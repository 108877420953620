import React from 'react';

const YouTubeVideoBlock = ({ url }) => {
  const embedUrl = url.replace("watch?v=", "embed/").split('&')[0]; // Ensure only the video ID part is used

  return (
    <div className="youtube-video-block">
      <iframe
        width="560"
        height="315"
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      ></iframe>
    </div>
  );
};

export default YouTubeVideoBlock;
