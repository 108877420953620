import React, { useState } from "react";
import "./MembershipInfo.css";
import { useTranslation } from "react-i18next";
import MembershipForm from "./MembershipForm";

import fullMemberImg from "../../../Images/1C1.jpg";
import familyMemberImg from "../../../Images/24.jpg";
import auxiliaryMemberImg from "../../../Images/2-2 (1).jpg";
import feeWaiverImg from "../../../Images/18.jpg";

const MembershipInfo = () => {
  const { t } = useTranslation();
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  const membershipTypes = [
    {
      title: t("TheClubRegistation.TheClubRegistationInfo3"),
      subtitle: t("TheClubRegistation.TheClubRegistationInfo3.1"),
      image: fullMemberImg,
    },
    {
      title: t("TheClubRegistation.TheClubRegistationInfo4"),
      subtitle: t("TheClubRegistation.TheClubRegistationInfo4.1"),
      image: familyMemberImg,
    },
    {
      title: t("TheClubRegistation.TheClubRegistationInfo5"),
      subtitle: t("TheClubRegistation.TheClubRegistationInfo5.1"),
      image: auxiliaryMemberImg,
    },
  ];

  return (
    <div className="membership-info-reg">
      <h2 className="membership-subtitle-reg">
        {t("TheClubRegistation.TheClubRegistationInfo1")}
      </h2>
      <div className="membership-cards-container">
        {membershipTypes.map((type, index) => (
          <React.Fragment key={index}>
            <div className="membership-card">
              <img
                src={type.image}
                alt={type.title}
                className="membership-image"
              />
              <div className="card-content">
                <h2 className="membership-type-title">{type.title}</h2>
                <p className="membership-type-title">{type.subtitle}</p>
              </div>
            </div>
            {type.title === t("TheClubRegistation.TheClubRegistationInfo5") && (
              <p className="membership-note">
                <p> {t("TheClubRegistation.TheClubRegistationInfo6")}</p>
                <p> {t("TheClubRegistation.TheClubRegistationInfo2")}</p>
              </p>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="new-members">
        <h2 className="membership-subtitles">
          {t("TheClubRegistation.TheClubRegistationInfo7")}
        </h2>
        <p>{t("TheClubRegistation.TheClubRegistationInfo8")}</p>
        <p>{t("TheClubRegistation.TheClubRegistationInfo9")}</p>
      </div>

     
      <button
        className="join-button"
        onClick={() => setShowMembershipForm(true)}
      >
        {t("TheClubRegistationForm.TheClubRegistationForm45")}
      </button>
      {showMembershipForm && (
        <MembershipForm onClose={() => setShowMembershipForm(false)} />
      )}
    </div>
  );
};

export default MembershipInfo;
