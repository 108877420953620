import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAbn9LDNxUGkfSPvF8cxKW-SnqXMg6d5wI",
  authDomain: "cpaa-ac0ff.firebaseapp.com",
  projectId: "cpaa-ac0ff",
  storageBucket: "cpaa-ac0ff.appspot.com",
  messagingSenderId: "812682063676",
  appId: "1:812682063676:web:33501b3b4443b76d687839",
  measurementId: "G-CZCNTJLPGC",
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const firebaseAuth = getAuth(app);
export const storage = getStorage(app);
