import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ParentComponent from "./Geral/ParentComponent";
import Footer from "./Geral/Footer";
import imageFile from "../Images/CPAA_Passeio-Geracoes_2024_Digital_8k-0652.jpg";
import "./SatisfactionSurvey.css";

const SatisfactionSurvey = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize navigate hook
  const [evaluation, setEvaluation] = useState("");
  const [routeEvaluation, setRouteEvaluation] = useState("");
  const [likedOptions, setLikedOptions] = useState([]);
  const [showOtherField, setShowOtherField] = useState(false);
  const [otherFeedback, setOtherFeedback] = useState("");
  const [improvementSuggestion, setImprovementSuggestion] = useState("");
  const [futureEventsSuggestion, setFutureEventsSuggestion] = useState("");
  const [routeFeedback, setRouteFeedback] = useState("");
  const [otherLikedFeedback, setOtherLikedFeedback] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleEvaluationChange = (event) => {
    setEvaluation(event.target.value);
  };

  const handleRouteEvaluationChange = (event) => {
    setRouteEvaluation(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setLikedOptions([...likedOptions, value]);
      if (value === "outro") {
        setShowOtherField(true);
      }
    } else {
      setLikedOptions(likedOptions.filter((option) => option !== value));
      if (value === "outro") {
        setShowOtherField(false);
        setOtherLikedFeedback("");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if required fields are filled
    if (!evaluation || !routeEvaluation || likedOptions.length === 0) {
      setErrorMessage("Preencha todos os campos com *");
      return;
    }

    setErrorMessage("");

    const emailContent = `
      <div style="font-family: Arial, sans-serif; line-height: 1.6;">
        <h2>${t("SatisfactionSurvey.title")}</h2>
        <p><strong>${t("SatisfactionSurvey.overallQuestion")}:</strong> ${evaluation}</p>
        ${otherFeedback ? `<p><strong>Outro Feedback:</strong> ${otherFeedback}</p>` : ""}
        <p><strong>${t("SatisfactionSurvey.routeEvaluationQuestion")}:</strong> ${routeEvaluation}</p>
        ${routeFeedback ? `<p><strong>Feedback de Percurso:</strong> ${routeFeedback}</p>` : ""}
        <p><strong>${t("SatisfactionSurvey.favoriteQuestion")}:</strong> ${likedOptions.join(", ")}</p>
        ${otherLikedFeedback ? `<p><strong>Outro (no favorito):</strong> ${otherLikedFeedback}</p>` : ""}
        <p><strong>${t("SatisfactionSurvey.improvementQuestion")}:</strong> ${improvementSuggestion}</p>
        <p><strong>${t("SatisfactionSurvey.futureEventsQuestion")}:</strong> ${futureEventsSuggestion}</p>
      </div>
    `;

    try {
      const response = await fetch(
        "https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            formType: "satisfactionSurvey",
            evaluation,
            routeEvaluation,
            likedOptions: likedOptions.join(", "),
            otherFeedback,
            improvementSuggestion,
            futureEventsSuggestion,
            routeFeedback,
            otherLikedFeedback,
            emailContent,
          }),
        }
      );

      if (response.ok) {
        alert("Formulário enviado com sucesso!");
        navigate("/"); // Redirect to homepage
      } else {
        alert(`Falha ao enviar o Formulário!`);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("Ocorreu um erro ao submeter o formulário.");
    }
  };

  return (
    <div className="satisfaction-survey-container">
      <ParentComponent />

      <div className="satisfaction-content">
        <div className="news-page-title-wrapper">
          <h1 className="article-title">{t("SatisfactionSurvey.title")}</h1>
        </div>
        <p className="required-fields-text">
          {t("SatisfactionSurvey.requiredFields")}
          <span className="required-asterisk"> *</span>
          {t("SatisfactionSurvey.requiredFieldsEnd")}
        </p>

        <img
          src={imageFile}
          alt={t("SatisfactionSurvey.imageAlt")}
          className="main-article-image-Survey"
        />
        <h1 className="membership-titleMatriculation">
          {t("SatisfactionSurvey.eventTitle")}
        </h1>

        <form onSubmit={handleSubmit}>
          {/* Survey Question */}
          <div className="survey-question-container">
            <p className="survey-question">
              {t("SatisfactionSurvey.overallQuestion")} <span style={{ color: "red" }}>&nbsp;*&nbsp;</span>
            </p>
            <div className="survey-options">
              <label>
                <input
                  type="radio"
                  name="evaluation"
                  value="excelente"
                  onChange={handleEvaluationChange}
                />
                {t("SatisfactionSurvey.optionVeryGood")}
              </label>
              <label>
                <input
                  type="radio"
                  name="evaluation"
                  value="bom"
                  onChange={handleEvaluationChange}
                />
                {t("SatisfactionSurvey.optionGood")}
              </label>
              <label>
                <input
                  type="radio"
                  name="evaluation"
                  value="razoavel"
                  onChange={handleEvaluationChange}
                />
                {t("SatisfactionSurvey.optionNeutral")}
              </label>
              <label>
                <input
                  type="radio"
                  name="evaluation"
                  value="ma"
                  onChange={handleEvaluationChange}
                />
                {t("SatisfactionSurvey.optionBad")}
              </label>
              <label>
                <input
                  type="radio"
                  name="evaluation"
                  value="muito_ma"
                  onChange={handleEvaluationChange}
                />
                {t("SatisfactionSurvey.optionVeryBad")}
              </label>
            </div>
          </div>

          {/* Conditional Text Area for Negative Feedback */}
          {["ma", "muito_ma"].includes(evaluation) && (
            <div className="additional-feedback">
              <p>{t("SatisfactionSurvey.feedbackPrompt")}</p>
              <textarea
                placeholder={t("SatisfactionSurvey.feedbackPlaceholder")}
                className="feedback-textarea"
                onChange={(e) => setOtherFeedback(e.target.value)}
                value={otherFeedback}
              ></textarea>
            </div>
          )}

          {/* Additional Survey Question */}
          <div className="survey-question-container">
            <p className="survey-question">
              {t("SatisfactionSurvey.routeEvaluationQuestion")} <span style={{ color: "red" }}>&nbsp;*&nbsp;</span>
            </p>
            <div className="survey-options">
              <label>
                <input
                  type="radio"
                  name="percurso_evaluation"
                  value="muito_bom"
                  onChange={handleRouteEvaluationChange}
                />
                {t("SatisfactionSurvey.optionVeryGood")}
              </label>
              <label>
                <input
                  type="radio"
                  name="percurso_evaluation"
                  value="bom"
                  onChange={handleRouteEvaluationChange}
                />
                {t("SatisfactionSurvey.optionGood")}
              </label>
              <label>
                <input
                  type="radio"
                  name="percurso_evaluation"
                  value="razoavel"
                  onChange={handleRouteEvaluationChange}
                />
                {t("SatisfactionSurvey.optionNeutral")}
              </label>
              <label>
                <input
                  type="radio"
                  name="percurso_evaluation"
                  value="mau"
                  onChange={handleRouteEvaluationChange}
                />
                {t("SatisfactionSurvey.optionBad")}
              </label>
              <label>
                <input
                  type="radio"
                  name="percurso_evaluation"
                  value="muito_mau"
                  onChange={handleRouteEvaluationChange}
                />
                {t("SatisfactionSurvey.optionVeryBad")}
              </label>
            </div>
          </div>

          {/* Conditional Text Area for Route Negative Feedback */}
          {["mau", "muito_mau"].includes(routeEvaluation) && (
            <div className="additional-feedback">
              <p>{t("SatisfactionSurvey.feedbackPrompt")}</p>
              <textarea
                placeholder={t("SatisfactionSurvey.feedbackPlaceholder")}
                className="feedback-textarea"
                onChange={(e) => setRouteFeedback(e.target.value)}
                value={routeFeedback}
              ></textarea>
            </div>
          )}

          {/* Checkbox Options */}
          <div className="survey-question-container">
            <p className="survey-question">
              {t("SatisfactionSurvey.favoriteQuestion")} <span style={{ color: "red" }}>&nbsp;*&nbsp;</span>
            </p>
            <div className="survey-options">
              <label>
                <input
                  type="checkbox"
                  value="percursos"
                  onChange={handleCheckboxChange}
                />
                {t("SatisfactionSurvey.optionRoutes")}
              </label>
              <label>
                <input
                  type="checkbox"
                  value="visitas_culturais"
                  onChange={handleCheckboxChange}
                />
                {t("SatisfactionSurvey.optionAccommodation")}
              </label>
              <label>
                <input
                  type="checkbox"
                  value="gastronomia"
                  onChange={handleCheckboxChange}
                />
                {t("SatisfactionSurvey.optionGastronomy")}
              </label>
              <label>
                <input
                  type="checkbox"
                  value="ambiente"
                  onChange={handleCheckboxChange}
                />
                {t("SatisfactionSurvey.optionFriendship")}
              </label>
              <label>
                <input
                  type="checkbox"
                  value="outro"
                  onChange={handleCheckboxChange}
                />
                {t("SatisfactionSurvey.optionOther")}
              </label>
            </div>
          </div>

          {showOtherField && (
            <div className="additional-feedback">
              <textarea
                placeholder={t("SatisfactionSurvey.otherPlaceholder")}
                className="feedback-textarea"
                onChange={(e) => setOtherLikedFeedback(e.target.value)}
                value={otherLikedFeedback}
              ></textarea>
            </div>
          )}

          {/* Suggestions */}
          <div className="suggestion-box">
            <p>{t("SatisfactionSurvey.improvementQuestion")}</p>
            <textarea
              placeholder={t("SatisfactionSurvey.suggestionPlaceholder")}
              className="feedback-textarea"
              onChange={(e) => setImprovementSuggestion(e.target.value)}
              value={improvementSuggestion}
            ></textarea>
          </div>

          <div className="suggestion-box">
            <p>{t("SatisfactionSurvey.futureEventsQuestion")}</p>
            <textarea
              placeholder={t("SatisfactionSurvey.suggestionPlaceholder")}
              className="feedback-textarea"
              onChange={(e) => setFutureEventsSuggestion(e.target.value)}
              value={futureEventsSuggestion}
            ></textarea>
          </div>

          {/* Submit button */}
          <button type="submit" className="join-button">
            {t("SatisfactionSurvey.submitButton")}
          </button>

          {/* Inline error message */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default SatisfactionSurvey;
