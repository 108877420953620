import React from "react";
import "./ClubInfo.css";
import { useTranslation } from "react-i18next";

const ClubInfo = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="club-info-container1">
      <h2 className="membership-titleCalendar">{t("TheClub.TheClub")}</h2>

      <div className="club-info-body">
        <h2>{t("TheClub.TheClub1")}</h2>
        <p>{t("TheClub.TheClub2")}</p>
        <p>{t("TheClub.TheClub3")}</p>
        <p>{t("TheClub.TheClub4")}</p>
        <p>{t("TheClub.TheClub5")}</p>
        <p>{t("TheClub.TheClub6")}</p>
        <p>{t("TheClub.TheClub7")}</p>
        <p>{t("TheClub.TheClub8")}</p>
        <p>{t("TheClub.TheClub9")}</p>
        <p>{t("TheClub.TheClub10")}</p>
        <p>{t("TheClub.TheClub11")}</p>
        <p>{t("TheClub.TheClub12")}</p>
        <p>{t("TheClub.TheClub13")}</p>
      </div>
    </div>
  );
};

export default ClubInfo;
