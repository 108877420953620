import React from "react";
import "./Footer.css";
import rightImage from "../../Images/FIVA-1.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <div id="site-footer">
      <div id="footer-widgets">
        <div className="wrapper">
          <div className="row">
            <div className="column">
              <strong>
                PORTO:
                <br />
              </strong>
              Rua de Serpa Pinto, 520/528
              <br />
              4250-464 Porto
              <br />
              {t("footer.Tel")}
              <br />
              porto@cpaa.pt
            </div>
            <div className="column">
              <strong>
                LISBOA:
                <br />
              </strong>
              Alameda Calouste Gulbenkian, 7<br />
              2770-023 Paço d’Arcos
              <br />
              214 410 633
              <br />
              lisboa@cpaa.pt
            </div>
            <div className="column">
              <strong>
                {t("footer.SCHEDULE")}
                <br />
              </strong>
              {t("footer.Clock")}

              <br />
              {t("footer.Clock2")}
              <br />
              {t("footer.Clock3")}
            </div>
            <div className="column">
              <a
                href="https://fiva.org/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={rightImage} alt="Visitar site" />
              </a>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="copyright">
              <span>Copyright © CPAA</span>
            </div>
            {/* Updated social media buttons */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
