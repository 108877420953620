import React, { useState, useEffect } from "react";
import "./Partners.css";
import { useTranslation } from "react-i18next";
import { firestore } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";

const PartnerCard = ({ descriptionEn, descriptionPt, imagePath, link }) => {
  const { i18n } = useTranslation();
  const description = i18n.language === 'en' ? descriptionEn : descriptionPt;

  return (
    <div className="partner-card" onClick={() => window.open(link, "_blank")}>
      <div className="partner-image-container">
        <img src={imagePath} alt="Partner" className="partner-image" />
        <div className="partner-overlay">
          <div className="partner-description">{description}</div>
        </div>
      </div>
    </div>
  );
};

const Partners = () => {
  const { t } = useTranslation();
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      const partnersCollectionRef = collection(firestore, "partners");
      const partnersSnapshot = await getDocs(partnersCollectionRef);
      const partnersList = partnersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPartners(partnersList);
    };

    fetchPartners();
  }, []);

  return (
    <div className="partners-container">
      <div className="partners-title">{t("Partner.Title")}</div>
      <div className="partners-grid">
        {partners.map((partner) => (
          <PartnerCard
            key={partner.id}
            descriptionEn={partner.descriptionEn}
            descriptionPt={partner.descriptionPt}
            imagePath={partner.imagePath}
            link={partner.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Partners;
