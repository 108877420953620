import React, { useRef, useEffect } from "react";
import "./Banner.css";
import { useTranslation } from "react-i18next";

function Banner() {
  const { t, i18n } = useTranslation();
  const bannerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.classList.add("visible");
        }
      },
      { threshold: 0.1 }
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  return (
    <div className="banner" ref={bannerRef}>
      <h2 className="slideFromLeft">{t("Banner.Left")}</h2>
      <h2 className="slideFromRight">{t("Banner.Right")}</h2>
    </div>
  );
}

export default Banner;
