import React from "react";
import "./TeamSection.css";
import localImage from "../../../Images/default.jpg";
import EngJorgeSeabra from "../../../Images/Eng_Jorge_Seabra.png";
import { useTranslation } from "react-i18next";
import CarlosSearaCardoso from "../../../Images/CarlosSearaCardoso.png";
import AdrianoPinheiro from "../../../Images/AdrianoPinheiro.png";
import LuisMiguelFonseca from "../../../Images/LuisMiguelFonseca.png";
import FilipeFigueiredo from "../../../Images/FilipeFigueiredo.png";
import JoseBarquinha from "../../../Images/JoseBarquinha.png";
import BessaPacheco from "../../../Images/BessaPacheco.png";
import CarlosGrilo from "../../../Images/CarlosGrilo.png"
import PedroAragão from "../../../Images/PedroAragão.png"
import PedroFilipe from "../../../Images/PedroFilipe.png"
import SofiaMendes from "../../../Images/SofiaMendes.png"
import LuisBrito from "../../../Images/Luis Brito.png"
import ManuelPinheiro from "../../../Images/Manuel Pinheiro.png"
import LuisFilipePereira from "../../../Images/LuisFilipePereira.png"
import FilipeBarrias from "../../../Images/FilipeBarrias.png"

const TeamSection = () => {
  const { t, i18n } = useTranslation();

  const teamMembersMesa = [
    {
      name: "Jorge Seabra",
      roleKey: "TheClubTeamSection.TheClubTeamRole",
      imgSrc: EngJorgeSeabra,
    },
    {
      name: "José Bessa Pacheco",
      roleKey: "TheClubTeamSection.TheClubTeamRole1",
      imgSrc: BessaPacheco,
    },
    {
      name: "Carlos Filipe Figueiredo",
      roleKey: "TheClubTeamSection.TheClubTeamRole2",
      imgSrc: FilipeFigueiredo,
    },
  ];
  const teamMembersDireção = [
    {
      name: "José Carlos Barquinha",
      roleKey: "TheClubTeamSection.TheClubTeamRole3",
      imgSrc: JoseBarquinha,
    },
    {
      name: "Pedro Aragão",
      roleKey: "TheClubTeamSection.TheClubTeamRole4",
      imgSrc: PedroAragão,
    },
    {
      name: "Luis Brito",
      roleKey: "TheClubTeamSection.TheClubTeamRole5",
      imgSrc: LuisBrito,
    },
    {
      name: "Pedro Filipe",
      roleKey: "TheClubTeamSection.TheClubTeamRole6",
      imgSrc: PedroFilipe,
    },
    {
      name: "Luis Filipe Pereira",
      roleKey: "TheClubTeamSection.TheClubTeamRole7",
      imgSrc: LuisFilipePereira,
    },
    {
      name: "Sofia Mendes",
      roleKey: "TheClubTeamSection.TheClubTeamRole8",
      imgSrc: SofiaMendes,
    },
    {
      name: "Carlos Grilo",
      roleKey: "TheClubTeamSection.TheClubTeamRole9",
      imgSrc: CarlosGrilo,
    },
  ];
  const teamMembersConselhoFiscal = [
    {
      name: "Manuel Pinheiro",
      roleKey: "TheClubTeamSection.TheClubTeamRole10",
      imgSrc: ManuelPinheiro,
    },
    {
      name: "Luís Fonseca",
      roleKey: "TheClubTeamSection.TheClubTeamRole11",
      imgSrc: LuisMiguelFonseca,
    },
    {
      name: "Carlos Seara Cardoso",
      roleKey: "TheClubTeamSection.TheClubTeamRole12",
      imgSrc: CarlosSearaCardoso,
    },
    {
      name: "Adriano Pinheiro",
      roleKey: "TheClubTeamSection.TheClubTeamRole13",
      imgSrc: AdrianoPinheiro,
    },
    {
      name: "Filipe Barrias",
      roleKey: "TheClubTeamSection.TheClubTeamRole14",
      imgSrc: FilipeBarrias,
    },
  ];

  const renderTeamMember = (member, index) => (
    <div className="team-member-card" key={index}>
      <img
        src={member.imgSrc}
        alt={member.name}
        className="team-member-image"
      />
      <div className="team-member-info">
        <h3 className="team-member-name">{member.name}</h3>
        <p className="team-member-role">{t(member.roleKey)}</p>
      </div>
    </div>
  );

  return (
    <section className="team-section">
      <h2 className="team-section-title">
        {t("TheClubTeamSection.TheClubTeamSection")}
      </h2>

      <div className="team-category">
        <h3>{t("TheClubTeamSection.TheClubTeamSection2")}</h3>
        <div className="team-members-container">
          {teamMembersMesa.map(renderTeamMember)}
        </div>
      </div>

      <div className="team-category">
        <h3>{t("TheClubTeamSection.TheClubTeamSection3")}</h3>
        <div className="team-members-container2">
          {teamMembersDireção.map(renderTeamMember)}
        </div>
      </div>

      <div className="team-category">
        <h3>{t("TheClubTeamSection.TheClubTeamSection4")}</h3>
        <div className="team-members-container3">
          {teamMembersConselhoFiscal.map(renderTeamMember)}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
