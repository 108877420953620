import React from "react";
import "./ImageComponent6.css";
import localImage from "../../../Images/passaporte_FIVA.jpg";

const ImageComponent = () => {
  return (
    <div className="image-container6">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
