import React, { useState, useEffect } from "react";
import "./style.css";
import { createRoot } from "react-dom";
import Routes from "./Routes/routes";
import "./i18n";
import Loading from "./Components/Loading";

const root = createRoot(document.getElementById("root"));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return <div className="App">{isLoading ? <Loading /> : <Routes />}</div>;
}

root.render(<App />);

export default App;
