import React from "react";
import "./FivaDescription.css";
import { useTranslation } from "react-i18next";
import guia_fiva from "../../../PDFs/guia_fiva-w.pdf";

const FivaDescription = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <div className="fiva-description-container3">
      <h1 className="fiva-description-title3">
        {t("FivaDescriptionGuide.FivaDescriptionGuide")}
      </h1>

      <p>{t("FivaDescriptionGuide.FivaDescriptionGuide2")}</p>
      <p>{t("FivaDescriptionGuide.FivaDescriptionGuide3")}</p>
      <p>{t("FivaDescriptionGuide.FivaDescriptionGuide4")}</p>
      <p>{t("FivaDescriptionGuide.FivaDescriptionGuide5")}</p>
      <p>{t("FivaDescriptionGuide.FivaDescriptionGuide6")}</p>
      <br></br>
      <br></br>
      <br></br>

      <a
        href={guia_fiva}
        className="download-button"
        role="button"
        aria-pressed="false"
        target="_blank" 
        rel="noopener noreferrer"
      >
        {t("FivaDescriptionGuide.FivaDescriptionGuide7")}
      </a>
    </div>
  );
};

export default FivaDescription;
