import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import userIcon from "../../../Images/people.png";
import emailIcon from "../../../Images/email.png";
import phoneIcon from "../../../Images/smartphone.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const CertificationForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    socioCpaa: "",
    morada: "",
    codigoPostal: "",
    localidade: "",
    marcaVeiculo: "",
    modeloVeiculo: "",
    anoFabrico: "",
    matricula: "",
    certificacao: "",
    comments: "",
    formType: "certification"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        onClose();
      } else {
        const errorText = await response.text();
        console.error("Failed to submit the form:", errorText);
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="membership-form-overlay">
      <div className="membership-form-container">
        <div className="membership-form-header">
          <h2 className="membership-form-title">
            {t("TheClubRegistationForm.TheClubRegistationForm55")}
          </h2>
          <button
            className="close-membership-form"
            onClick={onClose}
            aria-label="Close form"
          >
            ✕
          </button>
        </div>
        <form className="membership-form" onSubmit={handleSubmit}>
          <p className="input-label">
            {t("TheClubRegistationForm.TheClubRegistationForm47")}
          </p>

          <div className="form-group with-icon">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder={t("TheClubRegistationForm.TheClubRegistationForm2")}
              value={formData.name}
              onChange={handleChange}
              required
            />
            <img src={userIcon} alt="User Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder={t("TheClubRegistationForm.TheClubRegistationForm4")}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <img src={emailIcon} alt="Email Icon" className="input-icon" />
          </div>
          <div className="form-group phone-input-group">
            <PhoneInput
              international
              defaultCountry="PT"
              value={formData.phone}
              onChange={(phone) => setFormData((prevData) => ({ ...prevData, phone }))}
              className="my-phone-input"
              required
            />
            <img src={phoneIcon} alt="Phone Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="socioCpaa"
              className="form-control"
              placeholder="Sócio CPAA Nº"
              value={formData.socioCpaa}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="morada"
              className="form-control"
              placeholder="Morada"
              value={formData.morada}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="codigoPostal"
              className="form-control"
              placeholder="C. Postal"
              value={formData.codigoPostal}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="localidade"
              className="form-control"
              placeholder="Localidade"
              value={formData.localidade}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="marcaVeiculo"
              className="form-control"
              placeholder="Marca do Veículo"
              value={formData.marcaVeiculo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="modeloVeiculo"
              className="form-control"
              placeholder="Modelo"
              value={formData.modeloVeiculo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="anoFabrico"
              className="form-control"
              placeholder="Ano de Fabrico"
              value={formData.anoFabrico}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="matricula"
              className="form-control"
              placeholder="Matrícula"
              value={formData.matricula}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="certificacao"
              className="form-control"
              placeholder="Certificação Nº"
              value={formData.certificacao}
              onChange={handleChange}
            />
          </div>
          <p className="input-label">
            {t("TheClubRegistationForm.TheClubRegistationForm48")}
          </p>

          <div className="form-group">
            <textarea
              name="comments"
              className="form-control"
              value={formData.comments}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className="submit-button">
              {t("TheClubRegistationForm.TheClubRegistationForm46")}
            </button>
            <p className="submit-text">
              {t("TheClubRegistationForm.TheClubRegistationForm49")}
            </p>
          </div>
        </form>
        <div className="help-text">
          <p>{t("TheClubRegistationForm.TheClubRegistationForm50")}</p>
          <p>
            {t("recaptchaText")}{" "}
            {t("TheClubRegistationForm.TheClubRegistationForm51")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CertificationForm;
