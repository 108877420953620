import React from "react";
import "./ImageComponentPreClassic.css";
import localImage from "../../../Images/5B.jpg";
import { useTranslation } from "react-i18next";

const ImageComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-container-preclassic">
      <img
        src={localImage}
        alt="Join the Classic Car Club"
        className="banner-image-preclassic"
      />
      <div className="banner-overlay"></div> {}
      <div className="banner-text"></div>
    </div>
  );
};

export default ImageComponent;
