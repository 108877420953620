import React from "react";
import Footer from "./Geral/Footer";
import InternationalEventsContainer from "./Events/InternationalEventsContainer"; // Import the InternationalEventsContainer
import ParentComponent from "./Geral/ParentComponent";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";

const InternationalEventsApp = () => {
  return (
    <div>
      <ParentComponent />
      <InternationalEventsContainer />
      <SocialMediaButtons />
      <Footer />
    </div>
  );
};

export default InternationalEventsApp;
    