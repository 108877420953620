import React, { useState, useEffect } from "react";
import "./SocialMediaButtons.css";

const SocialMediaButtons = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setIsButtonVisible(!isVisible);
  };

  const handleMouseEnter = () => {
    if (isVisible) {
      setIsButtonVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setIsButtonVisible(false);
  };

  const handleButtonMouseEnter = () => {
    setIsButtonVisible(true);
  };

  const handleButtonMouseLeave = () => {
    setIsButtonVisible(false);
  };

  const arrowClass = isVisible ? "fa-chevron-up" : "fa-chevron-down";

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    const buttonTimeout = setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearTimeout(buttonTimeout);
    };
  }, []);

  return (
    <>
      <div
        className="social-media-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`social-btns fixed-social ${isVisible ? "show" : ""}`}>
          <a
            href="https://www.facebook.com/CPAA.pt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="box">
              <span className="face front">
                <i className="fab fa-facebook-f"></i>
              </span>
              <span className="face bottom">
                <i className="fab fa-facebook-f"></i>
              </span>
            </span>
          </a>
          <a
            href="https://www.linkedin.com/in/clube-portugu%C3%AAs-de-autom%C3%B3veis-antigos-9024302a2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="box">
              <span className="face front">
                <i className="fab fa-linkedin"></i>
              </span>
              <span className="face bottom">
                <i className="fab fa-linkedin"></i>
              </span>
            </span>
          </a>
          <a
            href="https://www.instagram.com/clubeportuguesautomoveisantigo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="box">
              <span className="face front">
                <i className="fab fa-instagram"></i>
              </span>
              <span className="face bottom">
                <i className="fab fa-instagram"></i>
              </span>
            </span>
          </a>
          {}
        </div>
        <button
          onClick={toggleVisibility}
          className={`toggle-visibility-button ${
            isButtonVisible ? "show" : ""
          }`}
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </>
  );
};

export default SocialMediaButtons;
