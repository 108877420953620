import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from "../../../firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';
import ParentComponent from "../../Geral/ParentComponent";
import Footer from "../../Geral/Footer";
import "./GalleryPage.css";

const GalleryPage = () => {
  const { t, i18n } = useTranslation();
  const { titlePt } = useParams();
  const [event, setEvent] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const q = query(collection(firestore, "events"), where("titlePt", "==", decodeURIComponent(titlePt)));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setEvent(querySnapshot.docs[0].data());
      } else {
        console.log("No such document!");
      }
    };

    fetchEvent();
  }, [titlePt]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageModal = (event) => {
    event.stopPropagation(); // Stop click from closing the modal
    setSelectedImageIndex(null);
  };

  const handleNext = (e) => {
    e.stopPropagation(); // Prevent modal from closing
    if (selectedImageIndex < event.galleryImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const handlePrev = (e) => {
    e.stopPropagation(); // Prevent modal from closing
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  return (
    <div>
      <ParentComponent />
      <div className="gallery-page">
        {event ? (
          <>
            <h1>{i18n.language === "pt" ? event.titlePt : event.titleEn}</h1>
            <p>{t('GalleryPage.description')}</p>
            <div className="image-gallery">
              {event.galleryImages.map((image, index) => (
                <img 
                  key={index} 
                  src={image} 
                  alt={`Gallery image ${index}`} 
                  className="gallery-image"
                  onClick={() => handleImageClick(index)}
                />
              ))}
              {selectedImageIndex !== null && (
                <div className="image-modal" onClick={() => setSelectedImageIndex(null)} {...swipeHandlers}>
                  <img src={event.galleryImages[selectedImageIndex]} alt="Enlarged view" className="enlarged-image" onClick={e => e.stopPropagation()} />
                  <button className="close-modal" onClick={closeImageModal}>✕</button>
                  {selectedImageIndex > 0 && (
                    <button className="modal-prev" onClick={handlePrev}>&#10094;</button>
                  )}
                  {selectedImageIndex < event.galleryImages.length - 1 && (
                    <button className="modal-next" onClick={handleNext}>&#10095;</button>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default GalleryPage;
