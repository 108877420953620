import React from "react";
import Footer from "./Geral/Footer";
import MembershipInfo from "./Club/TheClubRegistration/MembershipInfo";
import ParentComponent from "./Geral/ParentComponent";
import TopImage from "./Club/TheClubRegistration/TopImage";
import AdditionalInfo from "./Club/TheClubVantages/AdditionalInfo";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";

const App = () => {
  return (
    <div>
      <ParentComponent />
      <TopImage />
      <SocialMediaButtons />
      <MembershipInfo />
      <AdditionalInfo />
      <Footer />
    </div>
  );
};

export default App;
