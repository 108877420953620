import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./MembershipForm.css";
import userIcon from "../../../Images/people.png";
import emailIcon from "../../../Images/email.png";
import phoneIcon from "../../../Images/smartphone.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const MembershipForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    membershipType: "",
    morada: "",
    codigoPostal: "",
    localidade: "",
    biCc: "",
    validadeBiCc: "",
    nascimento: "",
    cartaConducao: "",
    validadeCarta: "",
    nif: "",
    comments: "",
    formType: "membership" // Add a formType field
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailContent = `
      <div style="font-family: Arial, sans-serif; line-height: 1.6;">
        <h2 style="color: #333;">Nova Inscrição CPAA</h2>
        <p><strong>Nome:</strong> ${formData.name}</p>
        <p><strong>Email:</strong> <a href="mailto:${formData.email}">${formData.email}</a></p>
        <p><strong>Telefone/Telemóvel:</strong> ${formData.phone}</p>
        <p><strong>Morada:</strong> ${formData.morada}</p>
        <p><strong>Código Postal:</strong> ${formData.codigoPostal}</p>
        <p><strong>Localidade:</strong> ${formData.localidade}</p>
        <p><strong>B.I./C.C.:</strong> ${formData.biCc}</p>
        <p><strong>Data de Validade (AAAA/MM/DD):</strong> ${formData.validadeBiCc}</p>
        <p><strong>Data de Nascimento (AAAA/MM/DD):</strong> ${formData.nascimento}</p>
        <p><strong>Carta de Condução:</strong> ${formData.cartaConducao}</p>
        <p><strong>Data de Validade (AAAA/MM/DD):</strong> ${formData.validadeCarta}</p>
        <p><strong>NIF:</strong> ${formData.nif}</p>
        <p><strong>Categoria de Sócio:</strong> ${formData.membershipType}</p>
        <p><strong>Comentários:</strong> ${formData.comments}</p>
      </div>
    `;

    try {
      const response = await fetch("https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          emailContent
        }),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        onClose(); // Close the form on successful submission
      } else {
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="membership-form-overlay">
      <div className="membership-form-container">
        <div className="membership-form-header">
          <h2 className="membership-form-title">
            {t("TheClubRegistationForm.TheClubRegistationForm44")}
          </h2>
          <button
            className="close-membership-form"
            onClick={onClose}
            aria-label="Close form"
          >
            ✕
          </button>
        </div>
        <form className="membership-form" onSubmit={handleSubmit}>
          <p className="input-label">
            {t("TheClubRegistationForm.TheClubRegistationForm47")}
          </p>

          <div className="form-group with-icon">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder={t("TheClubRegistationForm.TheClubRegistationForm2")}
              value={formData.name}
              onChange={handleChange}
              required
            />
            <img src={userIcon} alt="User Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder={t("TheClubRegistationForm.TheClubRegistationForm4")}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <img src={emailIcon} alt="Email Icon" className="input-icon" />
          </div>
          <div className="form-group phone-input-group">
            <PhoneInput
              international
              defaultCountry="PT"
              value={formData.phone}
              onChange={(phone) => setFormData((prevData) => ({ ...prevData, phone }))}
              className="my-phone-input"
              required
            />
            <img src={phoneIcon} alt="Phone Icon" className="input-icon" />
          </div>
          <div className="form-group">
            <select
              name="membershipType"
              className="form-control"
              value={formData.membershipType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                {t("TheClubRegistationForm.TheClubRegistationForm64")}
              </option>
              <option value="efetivo">
                {t("TheClubRegistationForm.TheClubRegistationForm65")}
              </option>
              <option value="auxiliar">
                {t("TheClubRegistationForm.TheClubRegistationForm66")}
              </option>
              <option value="familiar">
                {t("Finals.Finals3")}
              </option>
            </select>
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="morada"
              className="form-control"
              placeholder="Morada"
              value={formData.morada}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="codigoPostal"
              className="form-control"
              placeholder="Código Postal"
              value={formData.codigoPostal}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="localidade"
              className="form-control"
              placeholder="Localidade"
              value={formData.localidade}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="biCc"
              className="form-control"
              placeholder="B.I./C.C."
              value={formData.biCc}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <label htmlFor="validadeBiCc">{t("TheClubRegistationForm.TheClubRegistationForm73")}</label>
            <input
              type="date"
              name="validadeBiCc"
              className="form-control"
              value={formData.validadeBiCc}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <label htmlFor="nascimento">{t("TheClubRegistationForm.TheClubRegistationForm74")}</label>
            <input
              type="date"
              name="nascimento"
              className="form-control"
              value={formData.nascimento}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="cartaConducao"
              className="form-control"
              placeholder="Carta de Condução"
              value={formData.cartaConducao}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <label htmlFor="validadeCarta">{t("TheClubRegistationForm.TheClubRegistationForm75")}</label>
            <input
              type="date"
              name="validadeCarta"
              className="form-control"
              value={formData.validadeCarta}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="nif"
              className="form-control"
              placeholder="NIF"
              value={formData.nif}
              onChange={handleChange}
              required
            />
          </div>
          <p className="input-label">
            {t("TheClubRegistationForm.TheClubRegistationForm48")}
          </p>

          <div className="form-group">
            <textarea
              name="comments"
              className="form-control"
              value={formData.comments}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className="submit-button">
              {t("TheClubRegistationForm.TheClubRegistationForm46")}
            </button>
            <p className="submit-text">
              {t("TheClubRegistationForm.TheClubRegistationForm49")}
            </p>
          </div>
        </form>
        <div className="help-text">
          <p>{t("TheClubRegistationForm.TheClubRegistationForm50")}</p>
          <p>
            {t("recaptchaText")}{" "}
            {t("TheClubRegistationForm.TheClubRegistationForm51")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MembershipForm;
