import React from "react";
import Footer from "./Geral/Footer";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";
import HeroSection from "./HomePage/HeroSection";
import AnimatedBoxes from "./HomePage/AnimatedBoxes";
import NewsTitle from "./HomePage/NewsTitle";
import AnimatedBoxesNews from "./HomePage/AnimatedBoxesNews";
import ParentComponent from "./Geral/ParentComponent";
import Banner from "./HomePage/Banner";
import ParallaxSection from "./HomePage/ParallaxSection";
import AffiliatesSection from "./HomePage/AffiliatesSection";
import ParallaxSection2 from "./HomePage/ParallaxSection2";
const App = () => {
  return (
    <div>
      <ParentComponent />
      <HeroSection />
      <Banner />
      <SocialMediaButtons />
      <AnimatedBoxes />
      <ParallaxSection />
      <AffiliatesSection />
      <NewsTitle />
      <AnimatedBoxesNews />
      <ParallaxSection2 />
      <Footer />
    </div>
  );
};

export default App;
