import React from "react";
import "./CertificationsConditions1.css";
import { useTranslation } from "react-i18next";

const FivaDescription = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="fiva-description-container0">
      <br></br>

      <h1 className="fiva-description-title0">
        {t("CerteficationConditions.CerteficationConditions")}
      </h1>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions2")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions3")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions4")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions5")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions6")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions7")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions8")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions9")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions10")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions11")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions12")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions13")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions14")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions15")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions16")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions17")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions18")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions19")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions20")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions21")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions22")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions23")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions24")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions25")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions26")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions27")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions28")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions29")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions30")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions31")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions32")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions33")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions34")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions35")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions36")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions37")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions38")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions39")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions40")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions41")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions42")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions43")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions44")}</p>
      <p>{t("CerteficationConditions.CerteficationConditions45")}</p>
      <br></br>

      <h2 className="conditions-section">
        {t("CerteficationConditions.CerteficationConditions46")}
      </h2>
      <p>{t("CerteficationConditions.CerteficationConditions47")}</p>
      <p className="conditions-remark">
        {t("CerteficationConditions.CerteficationConditions48")}
      </p>
    </div>
  );
};

export default FivaDescription;
