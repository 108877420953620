import React from "react";
import "./Loading.css";
import logo from "../Images/Logo-CPAA_1-e1516357649395.png";

const Loading = () => {
  return (
    <div className="loading-container">
      <img src={logo} alt="Loading..." className="loading-logo" />
    </div>
  );
};

export default Loading;
