import React, { useState } from "react";
import "./StickyHeader.css";
import "./Navbar.css";
import "./TopBanner.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo1 from "../../Images/logo.png";
import logo2 from "../../Images/FivaLogoOficial.png";

const StickyHeader = () => {
  const { t, i18n } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isLoginActive, setIsLoginActive] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "pt" : "en";
    setLanguage(newLanguage);
    changeLanguage(newLanguage);
  };
  const toggleLogin = () => {
    setIsLoginActive(!isLoginActive);
  };
  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (index) => {
    if (activeDropdownIndex === index) {
      setActiveDropdownIndex(null);
    } else {
      setActiveDropdownIndex(index);
    }
  };
  const handleSearchToggle = () => {
    setIsSearchActive(!isSearchActive);
  };

  const menuClass = `menu ${isMenuOpen ? "open" : "flex"}`;

  return (
    <div className={`sticky-header ${isSearchActive ? "search-active" : ""}`}>
      {!isSearchActive && (
        <>
          <Link to="/">
            <img src={logo1} alt="Logo" className="sticky-logo logo1" />
            <img src={logo2} alt="Logo" className="sticky-logo logo2" />
          </Link>{" "}
          <nav className={menuClass}>
            <div className="head">
              <button
                type="button"
                className="close-menu-btn"
                onClick={toggleMenu}
              ></button>
            </div>
            <ul>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 1 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(1)}
              >
                <a href="#">{t("header.CLUB")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/CLUBE">
                      <span>{t("header.THECLUB")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEVANTAGES">
                      <span>{t("header.MEMBERADVANTAGES")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEREGISTATION">
                      <span>{t("header.MEMBERREGISTRATION")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEREPORT">
                      <span>{t("header.ACCOUNTSREPORT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEPOLICY">
                      <span>{t("header.PRIVACYPOLICY")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 2 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(2)}
              >
                <a href="#">{t("header.FIVA")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/FIVAORGANIZATION">
                      <span>{t("header.ORGANIZATIONANDOBJECTIVES")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/FIVALETTER">
                      <span>{t("header.TURINLETTER")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/FIVAGUIDE">
                      <span>{t("header.FIVAGUIDE")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/FIVAPASSPORT">
                      <span>{t("header.FIVAPASSPORT")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 3 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(3)}
              >
                <a href="#">{t("header.CERTIFICATIONS")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/CertificationsInfo">
                      <span>{t("header.INFORMATIONS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsConditions">
                      <span>{t("header.NECESSARYCONDITIONS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsBooking">
                      <span>{t("header.MARKING")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsCouncil">
                      <span>{t("header.TECHNICALCOUNCIL")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsCalendar">
                      <span>{t("header.CERTIFICATIONCALENDAR")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 4 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(4)}
              >
                <a href="#">{t("header.SEASONREGISTRATION")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/MembershipInformation">
                      <span>{t("header.INFORMATIONS")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 5 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(5)}
              >
                <a href="#">{t("header.INSURANCE")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/InsuranceClassic">
                      <span>{t("header.CLASSICS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/InsurancePreClassic">
                      <span>{t("header.PRE-CLASSICS")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 6 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(6)}
              >
                <a href="#">{t("header.EVENTS")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/EventsCalender">
                      <span>{t("header.CPAAEVENTSCALENDAR")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="EventsCalendarExpired/">
                      <span>{t("header.OTHEREVENTSHELD")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/InternationalEvents">
                      <span>{t("header.INTERNATIONEVENTS")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/Merchendising">{t("header.MERCHANDISING")} </Link>
              </li>
              <li>
                <Link to="/Vehicles">{t("header.RANKED")} </Link>
              </li>
              <li>
                <Link to="/Partners">{t("header.PARTNERSHIPS")} </Link>
              </li>
            </ul>
          </nav>
          <div className="sticky-icons">
            {/*<img src={searchIcon} alt="Search" onClick={handleSearchToggle} />*/}
            {/*
            <i
              className="fa fa-user"
              aria-hidden="true"
              onClick={toggleLogin}
            ></i>
            */}
            <div className="language-toggle" onClick={toggleLanguage}>
              <span className={language === "pt" ? "active" : ""}>PT</span>
              <span> | </span>
              <span className={language === "en" ? "active" : ""}>EN</span>
            </div>
            <Link to="/CLUBEREGISTATION">
              <button className="sticky-join-btn">{t("header.JOIN")}</button>
            </Link>
          </div>
        </>
      )}

      {isSearchActive && (
        <div className="sticky-search-bar">
          <input
            type="text"
            placeholder="Insira o termo a pesquisar"
            className="search-input"
          />
          <button onClick={handleSearchToggle} className="search-close-btn">
            X
          </button>
        </div>
      )}
      {isLoginActive && (
        <div className="login-form-container active">
          <span
            id="close-login-form"
            className="fas fa-times"
            onClick={toggleLogin}
          ></span>
          <form>
            <h3>{t("login.USERLOGIN")}</h3>
            <input type="email" placeholder="email" className="box" />
            <input type="password" placeholder="password" className="box" />
            <p>
              {" "}
              {t("login.FORGET")} <a href="#">{t("login.CLICK")}</a>{" "}
            </p>
            <input type="submit" value="login" className="btn" />
            <p>{t("login.ORLOGIN")}</p>
            <div className="buttons">
              <a href="#" className="btn">
                {" "}
                {t("login.GOOGLE")}{" "}
              </a>
              <a href="#" className="btn">
                {" "}
                {t("login.FACEBOOK")}{" "}
              </a>
            </div>
            <p>
              {" "}
              {t("login.Dont")} <a href="#">{t("login.CREATE")}</a>{" "}
            </p>
          </form>
        </div>
      )}
      <div className="fiva-logo-container">
        <Link to="/fiva-partnership">
          <img src={logo2} alt="FIVA Partner" className="fiva-logo" />
        </Link>
      </div>
    </div>
  );
};

export default StickyHeader;
