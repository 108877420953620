import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const createLinkPatternRegex = () => {
  return /<([^<]+)="([^"]+)">/g;
};

const parseContent = (content) => {
  const regex = createLinkPatternRegex();
  return content.replace(regex, (match, p1, p2) => {
    return `<a href="${p2}" target="_blank">${p1}</a>`;
  });
};

const ParagraphBlock = ({ content }) => {
  const { i18n } = useTranslation();
  const parsedContent = parseContent(i18n.language === 'en' ? content.en : content.pt);
  return (
    <div>
      {parse(parsedContent)}
    </div>
  );
};

export default ParagraphBlock;
