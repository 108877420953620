import React, { useState, useEffect } from "react";
import "./NewsPage.css";
import { useTranslation } from "react-i18next";
import { firestore } from "../../firebase-config";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";

const Article = ({ titlePt, title, image, firstParagraph, createdAt, t }) => {
  // Replace spaces with underscores in titlePt
  const sanitizedTitle = titlePt.replace(/ /g, '_');

  // Create a unique slug by combining sanitized titlePt and the timestamp
  const uniqueSlug = `${encodeURIComponent(sanitizedTitle)}-${createdAt}`;

  return (
    <div className="article">
      <img src={image} alt={title} className="article-image" />
      <div className="article-details">
        <h3 className="article-title-news-page">{title}</h3>
        <p className="article-description">{firstParagraph}</p>
        <Link to={`/article/${uniqueSlug}`} className="article-read-more">
          {t("TheClubVantages.readMore")}
        </Link>
      </div>
    </div>
  );
};


const NewsPage = () => {
  const [articles, setArticles] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesQuery = query(
        collection(firestore, "articles"),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(articlesQuery);
      const articlesData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const contentBlocks = data.contentBlocks || [];
        const firstParagraphBlock = contentBlocks.find(block => block.type === "paragraph");
        const firstParagraph = firstParagraphBlock ? firstParagraphBlock.content[i18n.language] || "" : "";
        return {
          titlePt: data.titlePt,
          title: data[`title${i18n.language === 'en' ? 'En' : 'Pt'}`],
          image: data.mainImage,
          firstParagraph,
          createdAt: data.createdAt.toMillis(),  // Get the timestamp in milliseconds
        };
      });
      setArticles(articlesData);
    };

    fetchArticles();
  }, [i18n.language]);

  return (
    <>
      <div className="news-page-title-container">
        <h1 className="news-page-title">{t("ArticleDetailPage.ArticleDetailPage30")}</h1>
      </div>
      <div className="news-page">
        {articles.map((article) => (
          <Article
            key={`${article.titlePt}-${article.createdAt}`}  // Use titlePt and createdAt as a unique key
            titlePt={article.titlePt}
            title={article.title}
            image={article.image}
            firstParagraph={article.firstParagraph}
            createdAt={article.createdAt}  // Pass createdAt to the Article component
            t={t}
          />
        ))}
      </div>
    </>
  );
};

export default NewsPage;
