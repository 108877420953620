// TitleBlock.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const TitleBlock = ({ titleEn, titlePt }) => {
  const { i18n } = useTranslation();

  return (
    <h1 className="article-title">
      {i18n.language === 'en' ? titleEn : titlePt}
    </h1>
  );
};

export default TitleBlock;
