import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { firestore } from "../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import "./SurveyDetailPage.css"; // Ensure it includes your necessary styles
import ParentComponent from "./Geral/ParentComponent";
import Footer from "./Geral/Footer";

const SurveyDetailPage = () => {
  const { slug } = useParams(); // This initially captures the survey ID
  const navigate = useNavigate();
  const [survey, setSurvey] = useState(null);
  const [justificationStates, setJustificationStates] = useState({});
  const [responses, setResponses] = useState({});
  const { i18n, t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const formatTitleForUrl = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-")      // Replace spaces with dashes
      .replace(/[^\w-]+/g, ""); // Remove special characters
  };

  useEffect(() => {
    const fetchSurvey = async () => {
      if (!slug) {
        console.error("Document ID (slug) is undefined");
        return;
      }

      try {
        // Fetch the specific survey using the ID (slug)
        const surveyDocRef = doc(firestore, "surveys", slug);
        const surveyDocSnap = await getDoc(surveyDocRef);

        if (surveyDocSnap.exists()) {
          const surveyData = surveyDocSnap.data();
          setSurvey(surveyData);

          // Format title and update URL
          const formattedTitle = formatTitleForUrl(
            i18n.language === "pt" ? surveyData.titlePt : surveyData.titleEn
          );
          navigate(`/inquerito/${formattedTitle}`, { replace: true });
        } else {
          console.log("No survey found with the specified document ID.");
        }
      } catch (error) {
        console.error("Error fetching survey:", error);
      }
    };

    fetchSurvey();
  }, [slug, navigate, i18n.language]);

  const handleOptionChange = (questionIndex, optionIndex, requiresJustification, isChecked, questionType) => {
    setJustificationStates((prevState) => {
      const updatedState = { ...prevState };

      if (questionType === "singleChoice") {
        updatedState[questionIndex] = requiresJustification && isChecked;
      } else if (questionType === "multipleChoice") {
        if (requiresJustification) {
          if (isChecked) {
            updatedState[questionIndex] = true;
          } else {
            const hasOtherJustificationOptionsChecked = survey.questions[questionIndex].options.some(
              (opt, idx) =>
                idx !== optionIndex &&
                opt.requiresJustification &&
                document.getElementById(`question-${questionIndex}-option-${idx}`).checked
            );
            updatedState[questionIndex] = hasOtherJustificationOptionsChecked;
          }
        }
      }

      return updatedState;
    });

    setResponses((prevState) => ({
      ...prevState,
      [questionIndex]: {
        ...prevState[questionIndex],
        [optionIndex]: isChecked,
        answerText: i18n.language === "pt" ? survey.questions[questionIndex].options[optionIndex].optionPt : survey.questions[questionIndex].options[optionIndex].optionEn,
      },
    }));
  };

  const handleTextInputChange = (questionIndex, value) => {
    setResponses((prevState) => ({
      ...prevState,
      [questionIndex]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!survey) return;

    let allValid = true;
    let collectedResponses = {};
    survey.questions.forEach((question, index) => {
      const inputName = `question-${index}`;
      let answer = "";
      let justification = "";

      if (question.type === "singleChoice") {
        const selectedOption = document.querySelector(`input[name="${inputName}"]:checked`);
        answer = selectedOption ? (i18n.language === "pt" ? selectedOption.dataset.optionPt : selectedOption.value) : "Não respondido";
        if (justificationStates[index] && selectedOption) {
          justification = document.querySelector(`textarea[name="${inputName}-justification"]`)?.value || "Nenhuma justificação";
        }
      } else if (question.type === "multipleChoice") {
        const selectedOptions = Array.from(document.querySelectorAll(`input[name="${inputName}"]:checked`));
        answer = selectedOptions.length > 0 ? selectedOptions.map((opt) => (i18n.language === "pt" ? opt.dataset.optionPt : opt.value)).join(", ") : "Nenhuma opção selecionada";
        if (justificationStates[index]) {
          justification = document.querySelector(`textarea[name="${inputName}-justification"]`)?.value || "Nenhuma justificação";
        }
      } else if (question.type === "text") {
        const textInput = document.querySelector(`textarea[name="${inputName}"]`);
        answer = textInput && textInput.value.trim() ? textInput.value.trim() : "Não respondido";
      }

      collectedResponses[`question-${index}`] = {
        questionText: i18n.language === "en" ? question.promptEn : question.promptPt,
        answer,
        justification,
      };

      if (question.mandatory && (!answer || answer === "Não respondido")) {
        allValid = false;
      }
    });

    if (!allValid) {
      setErrorMessage("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setErrorMessage("");

    try {
      const response = await fetch(
        "https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            formType: "satisfactionSurvey",
            eventName: survey.titlePt,
            collectedResponses,
          }),
        }
      );

      if (response.ok) {
        alert("Formulário enviado com sucesso!");
        navigate("/"); // Redirect to homepage on successful submission
      } else {
        alert("Falha ao enviar o Formulário!");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("Ocorreu um erro ao submeter o formulário.");
    }
  };

  const renderQuestion = (question, index) => {
    const isMandatory = question.mandatory;

    return (
      <div key={index} className="survey-question-container">
        <p className="survey-question">
          {i18n.language === "en" ? question.promptEn : question.promptPt}
          {isMandatory && <span className="required-asterisk"> *</span>}
        </p>
        {question.type === "singleChoice" && (
          <div className="survey-options">
            {question.options.map((option, optIndex) => (
              <div key={optIndex}>
                <label>
                  <input
                    type="radio"
                    name={`question-${index}`}
                    id={`question-${index}-option-${optIndex}`}
                    value={option.optionEn || option.optionPt}
                    data-option-pt={option.optionPt}
                    required={isMandatory}
                    onChange={() => handleOptionChange(index, optIndex, option.requiresJustification, true, "singleChoice")}
                  />
                  {i18n.language === "en" ? option.optionEn : option.optionPt}
                </label>
              </div>
            ))}
            {justificationStates[index] && (
              <textarea
                className="feedback-textarea"
                name={`question-${index}-justification`}
                placeholder={i18n.language === "en" ? "Please justify" : "Por favor, justifique"}
                onChange={(e) => handleTextInputChange(index, e.target.value)}
              ></textarea>
            )}
          </div>
        )}
        {question.type === "multipleChoice" && (
          <div className="survey-options">
            {question.options.map((option, optIndex) => (
              <div key={optIndex}>
                <label>
                  <input
                    type="checkbox"
                    name={`question-${index}`}
                    id={`question-${index}-option-${optIndex}`}
                    value={option.optionEn || option.optionPt}
                    data-option-pt={option.optionPt}
                    onChange={(e) => handleOptionChange(index, optIndex, option.requiresJustification, e.target.checked, "multipleChoice")}
                  />
                  {i18n.language === "en" ? option.optionEn : option.optionPt}
                </label>
              </div>
            ))}
            {justificationStates[index] && (
              <textarea
                className="feedback-textarea"
                name={`question-${index}-justification`}
                placeholder={i18n.language === "en" ? "Please justify" : "Por favor, justifique"}
                onChange={(e) => handleTextInputChange(index, e.target.value)}
              ></textarea>
            )}
          </div>
        )}
        {question.type === "text" && (
          <div className="additional-feedback">
            <textarea
              name={`question-${index}`}
              className="feedback-textarea"
              placeholder={i18n.language === "en" ? "Your answer" : "Sua resposta"}
              required={isMandatory}
              onChange={(e) => handleTextInputChange(index, e.target.value)}
            ></textarea>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="satisfaction-survey-container">
      <ParentComponent />
      <div className="satisfaction-content">
        <div className="news-page-title-wrapper">
          <h1 className="article-title">{t("SatisfactionSurvey.title")}</h1>
          <div className="info-text">
            {t("SatisfactionSurvey.requiredFields")}
            <span className="required-asterisk"> *</span>
            {t("SatisfactionSurvey.requiredFieldsEnd")}
          </div>
        </div>
        {survey && survey.image && (
          <img
            src={survey.image}
            alt={i18n.language === "en" ? "Survey Image" : "Imagem da Pesquisa"}
            className="main-article-image-Survey"
          />
        )}
        {survey && (
          <h2 className="survey-title">
            {i18n.language === "en" ? survey.titleEn : survey.titlePt}
          </h2>
        )}
        <form onSubmit={handleSubmit}>
          {survey ? (
            survey.questions.map((question, index) => renderQuestion(question, index))
          ) : (
            <p>Loading...</p>
          )}
          <button type="submit" className="join-button">
            {t("SatisfactionSurvey.submitButton")}
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default SurveyDetailPage;

