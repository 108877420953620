import React, { useState } from "react";
import "./EventFilter.css";
import { useTranslation } from "react-i18next";

const EventFilter = ({ onYearChange, onMonthChange, onTypeChange }) => {
  const { t, i18n } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedType, setSelectedType] = useState("Classicos");

  const months = t("months", { returnObjects: true });

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
    if (onYearChange) {
      onYearChange(newYear);
    }
  };

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setSelectedMonth(newMonth);
    if (onMonthChange) {
      onMonthChange(newMonth);
    }
  };

  const displaySelectedFilters = () => {
    const monthIndex = parseInt(selectedMonth) - 1;
    const monthTranslation = selectedMonth ? Object.values(months)[monthIndex] : t("events.selectMonth2");
    return `${selectedYear} / ${monthTranslation} / ${t("events.selectMonth3")}`;
  };

  return (
    <div className="event-filter-wrapper">
      <div className="header-and-selected-filters">
        <h1 className="main-title">{t("Finals.Finals")}</h1>
        <div className="selected-filters">
          {displaySelectedFilters()}
        </div>
      </div>
      <div className="filter-section">
        <h3 className="filter-heading">{t("Finals.Finals2")}</h3>
        <div className="filters">
          <select value={selectedType} onChange={onTypeChange} disabled>
            <option value="Classicos">{t("Classicos")}</option>
          </select>
          <select value={selectedYear} onChange={handleYearChange}>
            {[...Array(5).keys()].map((offset) => {
              const year = new Date().getFullYear() + offset;
              return (
                <option key={year} value={year.toString()}>
                  {year}
                </option>
              );
            })}
          </select>
          <select value={selectedMonth} onChange={handleMonthChange}>
            <option value="">{t("events.selectMonth")}</option>
            {Object.keys(months).map((key, index) => (
              <option key={key} value={index + 1}>
                {months[key]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default EventFilter;
