import React from "react";
import "./DocumentsList.css";
import pdfIcon from "../../../Images/pfd.png";
import { useTranslation } from "react-i18next";
import pdfFile2022 from "../../../PDFs/RELATORIO-E-CONTAS-CPAA-2022-OFICIAL.pdf";
import pdfFile2021 from "../../../PDFs/relatorio_contas_2021.pdf";
import pdfFile2020 from "../../../PDFs/relatorio_contas_2020.pdf";
import pdfFile2019 from "../../../PDFs/relatorio_contas_2019.pdf";
import pdfFile2018 from "../../../PDFs/relatorio_contas_2018.pdf";
import pdfFile2023 from "../../../PDFs/relatorio_contas_2023.pdf";

const DocumentsList = () => {
  const { t, i18n } = useTranslation();

  const documents = [
    {
      title: t("TheClubReportDocument.TheClubReportDocument7"),
      href: pdfFile2023,
    },
    {
      title: t("TheClubReportDocument.TheClubReportDocument2"),
      href: pdfFile2022,
    },
    {
      title: t("TheClubReportDocument.TheClubReportDocument3"),
      href: pdfFile2021,
    },
    {
      title: t("TheClubReportDocument.TheClubReportDocument4"),
      href: pdfFile2020,
    },
    {
      title: t("TheClubReportDocument.TheClubReportDocument5"),
      href: pdfFile2019,
    },
    {
      title: t("TheClubReportDocument.TheClubReportDocument6"),
      href: pdfFile2018,
    },
  ];

  const handleCardClick = (href) => {
    window.open(href, "_blank");
  };

  return (
    <div className="documents-container">
      <h2 className="documents-title">
        {t("TheClubReportDocument.TheClubReportDocument")}
      </h2>
      <div className="documents-grid">
        {documents.map((doc, index) => (
          <div
            key={index}
            className="document-item"
            onClick={() => handleCardClick(doc.href)}
          >
            <img src={pdfIcon} alt="PDF icon" className="document-icon" />
            <div className="document-text">{doc.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentsList;

