// SubtitleBlock.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const SubtitleBlock = ({ content }) => {
  const { t, i18n } = useTranslation();

  return (
    <h2 className="subtitle">
      {i18n.language === 'en' && content.en ? `${content.en} / ` : ''}
      {i18n.language === 'pt' && content.pt ? content.pt : content.en}
    </h2>
  );
};

export default SubtitleBlock;
