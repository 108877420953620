import React, { useRef, useEffect } from "react";
import "./AnimatedBoxes.css";
import image1 from "../../Images/IMG_1046.jpg";
import image2 from "../../Images/certificacao_antiga.jpeg";
import image3 from "../../Images/CPAA-Feminino-Primarias-4k-7670-scaled.jpg";
import image4 from "../../Images/IMG_2261.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AnimatedBoxes = () => {
  const boxRefs = useRef([]);
  const { t } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            obs.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    boxRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      boxRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const services = [
    {
      name: t("services.NAME1"),
      role: t("services.ROLE1"),
      image: image1,
      url: "/CLUBEREGISTATION",
    },
    {
      name: t("services.NAME2"),
      role: t("services.ROLE2"),
      image: image2,
      url: "/CertificationsBooking",
    },
    {
      name: t("services.NAME3"),
      role: t("services.ROLE3"),
      image: image3,
      url: "/InsuranceClassic",
    },
    {
      name: t("services.NAME4"),
      role: t("services.ROLE4"),
      image: image4,
      url: "/EventsCalender",
    },
  ];

  return (
    <div className="animated-boxes-container">
      {services.map((service, index) => (
        <div
          key={index}
          className="cardboxes"
          ref={(el) => (boxRefs.current[index] = el)}
          style={{ backgroundImage: `url(${service.image})` }}
        >
          <div className="overlay">
            <div className="text-content">
              <h3>{service.name}</h3>
              <p>{service.role}</p>
              <Link to={service.url}>
                <button className="learn-more-btn">
                  {t("services.BUTTON")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnimatedBoxes;
