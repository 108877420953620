import React from "react";
import "./ImageComponent4.css";
import localImage from "../../../Images/FIVA-20230528-091.jpg";

const ImageComponent = () => {
  return (
    <div className="image-container-Organization">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
