import React from 'react';
import { useTranslation } from 'react-i18next';

const ProgramBlock = ({ dateEn, datePt, activities }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <div className="program-block">
      <h2>{language === 'en' ? dateEn : datePt}</h2>
      {activities.map((activity, index) => (
        <p key={index}>{language === 'en' ? activity.en : activity.pt}</p>
      ))}
    </div>
  );
};

export default ProgramBlock;
