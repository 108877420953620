import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";

import MainHeader from "./MainHeader";
import StickyHeader from "./StickyHeader";

const Navbar = () => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const mainHeaderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (mainHeaderRef.current) {
        const mainHeaderHeight = mainHeaderRef.current.offsetHeight;
        console.log(
          "Scroll Y: ",
          window.scrollY,
          "MainHeader Height: ",
          mainHeaderHeight
        );
        setShowStickyHeader(window.scrollY > mainHeaderHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div ref={mainHeaderRef}>
        <MainHeader />
      </div>
      {showStickyHeader && <StickyHeader />}
    </>
  );
};

export default Navbar;
