import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebase-config";
import { useTranslation } from "react-i18next";
import EventFilter from "../EventFilter/EventFilter";
import { Link } from "react-router-dom";
import "../EventsCalendar/Ticket.css";

const TicketsExpired = () => {
  const [events, setEvents] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [articles, setArticles] = useState({});
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const today = new Date();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollectionRef = collection(firestore, "events");
        const eventsSnapshot = await getDocs(eventsCollectionRef);
        const eventsList = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsList);

        // Fetch article titles for linked events
        const articlesData = {};
        for (const event of eventsList) {
          if (event.articleId) {
            const docRef = doc(firestore, "articles", event.articleId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              articlesData[event.articleId] = docSnap.data().titlePt;
            }
          }
        }
        setArticles(articlesData);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const getMonthName = (monthIndex) => {
    const monthNames = t("months", { returnObjects: true });
    return Object.values(monthNames)[monthIndex];
  };

  const isWithinFilter = (eventDate, filterYear, filterMonth) => {
    if (!eventDate) return false;

    const [eventYear, eventMonth] = eventDate.split("-");

    if (filterYear && eventYear !== filterYear) {
      return false;
    }

    const eventMonthName = getMonthName(parseInt(eventMonth, 10) - 1);
    const filterMonthName = getMonthName(parseInt(filterMonth, 10) - 1);
    if (filterMonth && eventMonthName !== filterMonthName) {
      return false;
    }

    return true;
  };

  const createDateFromEvent = (eventDate) => {
    const [year, month, day] = eventDate.split("-");
    return new Date(year, month - 1, day);
  };

  const filteredAndSortedEvents = events
    .filter(event => event.eventType === "national") // Filter to show only national past events
    .filter((event) =>
      isWithinFilter(event.startDate, year, month) ||
      isWithinFilter(event.endDate, year, month))
    .sort((a, b) => createDateFromEvent(a.startDate) - createDateFromEvent(b.startDate));

  const pastEvents = filteredAndSortedEvents.filter(event => createDateFromEvent(event.endDate) < today);

  const formatEventDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startDay = start.getDate();
    const endDay = end.getDate();
    const startMonth = getMonthName(start.getMonth());
    const endMonth = getMonthName(end.getMonth());

    if (startDate === endDate) {
      return (
        <>
          <div className="event-date-day">{startDay}</div>
          <div className="event-date-month">{startMonth}</div>
        </>
      );
    } else if (startMonth === endMonth) {
      return (
        <>
          <div className="event-date-day">{`${startDay}-${endDay}`}</div>
          <div className="event-date-month">{startMonth}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="event-date-day">{startDay}</div>
          <div className="event-date-month">{startMonth}</div>
          <div className="event-date-day">{endDay}</div>
          <div className="event-date-month">{endMonth}</div>
        </>
      );
    }
  };

  return (
    <div>
      <EventFilter onYearChange={setYear} onMonthChange={setMonth} />
      <div className="events-list-container">
        {pastEvents.length > 0 ? (
          <>
            {pastEvents.map((event) => (
              <Link 
                key={event.id} 
                to={
                  event.galleryImages && event.galleryImages.length > 0 
                    ? `/gallery/${encodeURIComponent(event.titlePt)}`
                    : event.articleId && articles[event.articleId]
                      ? `/article/${encodeURIComponent(articles[event.articleId])}`
                      : '#'
                }
              >
                <div className="event-card">
                  <div className="event-image-container">
                    <img
                      src={event.mainImage}
                      alt={currentLanguage === "pt" ? event.titlePt : event.titleEn}
                      className="event-image"
                    />
                    <div className="event-date-container">
                      {formatEventDate(event.startDate, event.endDate)}
                    </div>
                  </div>
                  <div className="event-title">
                    {currentLanguage === "pt" ? event.titlePt : event.titleEn}
                  </div>
                </div>
              </Link>
            ))}
          </>
        ) : (
          <div className="no-events-message">
            {t("Events.noEventsThisMonth")}{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketsExpired;
