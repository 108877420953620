import React from "react";
import "./ImageComponent2.css";
import localImage from "../../../Images/1B.jpg";

const ImageComponent = () => {
  return (
    <div className="image-container2">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
