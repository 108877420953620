import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./AdditionalInfo.css";
import { firestore } from "../../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";

const AdditionalInfo = () => {
  const [articles, setArticles] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "articles"));
        const fetchedArticles = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt.toMillis(), // Convert Firestore timestamp to milliseconds
          };
        });

        const shuffledArticles = fetchedArticles.sort(() => 0.5 - Math.random());
        setArticles(shuffledArticles.slice(0, 3));
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="additional-info-container">
      <h2 className="additional-info-header">{t("TheClubVantages.header")}</h2>
      <div className="info-cards-container">
        {articles.map((article) => {
          // Replace spaces with underscores in titlePt
          const sanitizedTitle = article.titlePt.replace(/ /g, '_');

          // Create a unique slug by combining sanitized titlePt and the timestamp
          const uniqueSlug = `${encodeURIComponent(sanitizedTitle)}-${article.createdAt}`;

          return (
            <div className="info-card" key={article.id}>
              <img
                className="info-card-image"
                src={article.mainImage}
                alt={i18n.language === "en" ? article.titleEn : article.titlePt}
              />
              <div className="info-card-content">
                <div className="info-card-title">
                  {i18n.language === "en" ? article.titleEn : article.titlePt}
                </div>
                <div className="info-card-description">{article.summary}</div>
                <Link
                  to={`/article/${uniqueSlug}`}
                  className="info-card-read-more"
                >
                  {t("services.BUTTON")}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdditionalInfo;
