import React from "react";
import "./ImageComponentConditions.css";
import localImage from "../../../Images/3B.jpg";
import { useTranslation } from "react-i18next";

const ImageComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-container">
      <img 
        src={localImage}
        alt="Join the Classic Car Club"
        className="banner-image2"
      />
      <div className="banner-overlay"></div> {}
      <div className="banner-text"></div>
    </div>
  );
};

export default ImageComponent;
