import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import "./VehiclesList.css";
import { useTranslation } from "react-i18next";

const VehiclesList = () => {
  const [vehicles, setVehicles] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchVehicles = async () => {
      const querySnapshot = await getDocs(collection(firestore, "vehicles"));
      const vehiclesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort vehiclesData alphabetically by title
      vehiclesData.sort((a, b) => a.title.localeCompare(b.title, i18n.language));

      setVehicles(vehiclesData);
    };

    fetchVehicles();
  }, [i18n.language]); // Add i18n.language as a dependency to refetch when the language changes

  return (
    <div>
      <div className="vehicles-list-title">
        <h1>{t("VehiclesC.Title")}</h1>
      </div>
      <div className="vehicles-list">
        {vehicles.map((vehicle) => (
          <Link to={`/Vehicle/${vehicle.id}`} key={vehicle.id}>
            <div className="vehicle-card">
              <img
                src={vehicle.mainImage}
                alt={vehicle.title}
                className="vehicle-image"
              />
              <div className="vehicle-details">
                <h2>{vehicle.title}</h2>
                <Link to={`/Vehicle/${vehicle.id}`} className="read-more-link">
                  {t("Vehicles.Button")}
                </Link>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default VehiclesList;
