import React from "react";
import "./ServicesTitle.css";
import { useTranslation } from "react-i18next";

const NewsTitle = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="services-title-container">
      <h1 className="title">{t("Articles.Articles2")}</h1>
    </div>
  );
};

export default NewsTitle;
