import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { firestore } from "../../firebase-config";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import "./VehicleInfo.css";

const VehicleInfo = () => {
  const { vehicleId } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [otherVehicles, setOtherVehicles] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const imgDisplayRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { t, i18n } = useTranslation();
  const languageKey = i18n.language.split("-")[0];

  const handleNextImage = () => {
    if (vehicle && vehicle.imageGallery) {
      setSelectedImageIndex(
        (prevIndex) => (prevIndex + 1) % vehicle.imageGallery.length
      );
    }
  };

  const handlePrevImage = () => {
    if (vehicle && vehicle.imageGallery) {
      setSelectedImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + vehicle.imageGallery.length) %
          vehicle.imageGallery.length
      );
    }
  };

  const handleThumbnailClick = (index, event) => {
    event.preventDefault();
    setSelectedImageIndex(index);
  };

  const toggleFullScreen = () => {
    if (imgDisplayRef.current) {
      const requestFullScreen =
        imgDisplayRef.current.requestFullscreen ||
        imgDisplayRef.current.mozRequestFullScreen ||
        imgDisplayRef.current.webkitRequestFullscreen ||
        imgDisplayRef.current.msRequestFullscreen;
      const exitFullScreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        requestFullScreen.call(imgDisplayRef.current);
      } else {
        exitFullScreen.call(document);
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const fetchVehicle = async () => {
      const docRef = doc(firestore, "vehicles", vehicleId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setVehicle({ id: docSnap.id, ...docSnap.data() });
      }
    };

    const fetchOtherVehicles = async () => {
      const querySnapshot = await getDocs(collection(firestore, "vehicles"));
      const vehiclesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOtherVehicles(vehiclesData.filter((v) => v.id !== vehicleId));
    };

    fetchVehicle();
    fetchOtherVehicles();
  }, [vehicleId]);

  return (
    <section className="card-wrapper">
      {vehicle && (
        <div className="card">
          <div className="product-imgs">
            <div className="img-display" ref={imgDisplayRef}>
              {vehicle.imageGallery.length > 1 && (
                <button onClick={handlePrevImage} className="img-nav prev">
                  &#10094;
                </button>
              )}
              <img
                src={vehicle.imageGallery[selectedImageIndex]}
                alt="Vehicle Display"
                className="img-showcase"
                onClick={toggleFullScreen}
              />
              {vehicle.imageGallery.length > 1 && (
                <button onClick={handleNextImage} className="img-nav next">
                  &#10095;
                </button>
              )}
              <button onClick={toggleFullScreen} className="fullscreen-toggle">
                {isFullScreen ? "Exit Full Screen" : "Full Screen"}
              </button>
            </div>
            <div className="img-select">
              {vehicle.imageGallery.map((image, index) => (
                <div className="img-item" key={index}>
                  <a
                    href="#"
                    onClick={(event) => handleThumbnailClick(index, event)}
                  >
                    <img src={image} alt={`Image ${index}`} />
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="product-content">
            <h2 className="product-title">{vehicle.title}</h2>
            <div className="product-detail">
              <p>{t("Vehicles.Spec")} {vehicle.memberNr}</p>
              <p>{t("Vehicles.Spec2")}{vehicle.name}</p>
              <p>{t("Vehicles.Spec3")} {vehicle.brand}</p>
              <p>{t("Vehicles.Spec4")} {vehicle.model}</p>
              <p>{t("Vehicles.Spec5")} {vehicle.year}</p>
              <p>{t("Vehicles.Spec6")} {vehicle.plate}</p>
              <p>{t("Vehicles.Spec7")} {vehicle.certCPAA}</p>
              <p>{t("Vehicles.Spec8")} {vehicle.price}€</p>
              <p className="product-contact"><strong>{t("Vehicles.Spec9")} {vehicle.contact}</strong></p>
            </div>
          </div>
        </div>
      )}
      <div className="other-vehicles-divider">
        <span className="vc_sep_holder vc_sep_holder_l">
          <span className="vc_sep_line"></span>
        </span>
        <h4>{t("Vehicles.Others")}</h4>
        <span className="vc_sep_holder vc_sep_holder_r">
          <span className="vc_sep_line"></span>
        </span>
      </div>
      <div className="other-vehicles">
        {otherVehicles.map((v) => (
          <Link key={v.id} to={`/vehicle/${v.id}`} className="vehicle-card">
            <img src={v.mainImage} alt={v.title} className="vehicle-image" />
            <div className="vehicle-details">
              <h2>{v.title}</h2>
              <div className="read-more-link">{t("Vehicles.Button")}</div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default VehicleInfo;
