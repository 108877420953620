import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase-config";

const RegistrationFormPorto = ({ onClose, eventId }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate(); // Initialize navigate hook

  // Initial state with additional field for registrationDeadline
  const [eventDetails, setEventDetails] = useState({
    titleEn: "",
    titlePt: "",
    pricePerPerson: "",
    registrationDeadline: "", // Added registrationDeadline
  });

  const [formData, setFormData] = useState({
    name: "",
    memberNumber: "",
    email: "",
    phone: "",
    companion: "",
    numberOfPersons: 1,
    foodRestrictions: "",
    formType: "portoRegistration",
    eventName: "", // Will be set after fetching event details
  });

  // Fetch event details when component mounts or eventId changes
  useEffect(() => {
    const fetchEventDetails = async () => {
      if (!eventId) {
        console.error("Invalid event ID provided");
        return;
      }

      try {
        const docRef = doc(firestore, "articles", eventId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const eventData = docSnap.data();
          console.log("Fetched document data:", eventData);

          const firstContentBlock = eventData.contentBlocks?.[0];
          const details = firstContentBlock?.content?.details;

          if (details) {
            const { pricePerPerson, titleEn, titlePt, registrationDeadline } = details;
            console.log("Extracted pricePerPerson:", pricePerPerson);
            console.log("Extracted registrationDeadline:", registrationDeadline);

            setEventDetails({
              titleEn: titleEn || eventData.titleEn,
              titlePt: titlePt || eventData.titlePt,
              pricePerPerson: pricePerPerson || "No price available",
              registrationDeadline: registrationDeadline || "No deadline available", // Set registrationDeadline
            });

            setFormData((prevData) => ({
              ...prevData,
              eventName: titleEn || titlePt,
            }));
          } else {
            console.error("contentBlocks[0].content.details not found in document");
            setEventDetails((prev) => ({
              ...prev,
              pricePerPerson: "Details not available",
            }));
          }
        } else {
          console.error("No such event found!");
        }
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert("Formulário enviado com sucesso!");
        navigate("/"); // Redirect to homepage
      } else {
        alert(`Falha ao enviar o Formulário!`);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="registration-form-overlay">
      <div className="registration-form-container">
        <div className="registration-form-header">
          <h2 className="registration-title">
            {language === "en" ? eventDetails.titleEn : eventDetails.titlePt}
          </h2>
        </div>
        <form className="registration-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">{t("ArticleDetailPage.Name")}</label>
            <input type="text" id="name" required value={formData.name} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="memberNumber">{t("ArticleDetailPage.ArticleDetailPage3")}</label>
            <input type="text" id="memberNumber" required value={formData.memberNumber} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t("ArticleDetailPage.ArticleDetailPage4")}</label>
            <input type="email" id="email" required value={formData.email} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="phone">{t("ArticleDetailPage.ArticleDetailPage5")}</label>
            <input type="text" id="phone" required value={formData.phone} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="companion">{t("ArticleDetailPage.ArticleDetailPage6")}</label>
            <input type="text" id="companion" value={formData.companion} onChange={handleInputChange} />
          </div>
          <div className="pricing-info">
            <h3>{t("ArticleDetailPage.PricePerPerson")}</h3>
            <p>{eventDetails.pricePerPerson || "35 €"}</p>
          </div>
          <div className="form-group">
            <label htmlFor="numberOfPersons">{t("ArticleDetailPage.NumberOfPersons")}</label>
            <input type="number" id="numberOfPersons" min="1" required value={formData.numberOfPersons} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="foodRestrictions">{t("ArticleDetailPage.FoodRestrictions")}</label>
            <input type="text" id="foodRestrictions" value={formData.foodRestrictions} onChange={handleInputChange} />
          </div>
          <div className="additional-info">
            <p>{t("ArticleDetailPage.ArticleDetailPage22")}</p>
            <p>{t("ArticleDetailPage.ArticleDetailPage23")}</p>
            <p>IBAN PT50 0033.0000.0004.7957.6740.5 - Millennium BCP</p>
            <p>{t("ArticleDetailPage.ArticleDetailPage24")} {eventDetails.registrationDeadline || "2024-12-07"}</p> {/* Display deadline */}
            <p>{t("ArticleDetailPage.ArticleDetailPage26")}</p>
            <p>{t("ArticleDetailPage.ArticleDetailPage27")}</p>
          </div>
          <button type="submit" className="submit-button">
            {t("ArticleDetailPage.ArticleDetailPage28")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistrationFormPorto;
