import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { firestore } from "../../firebase-config";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import ProductForm from "./ProductForm";
import "./ProductInfo.css";

const ProductInfo = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [otherProducts, setOtherProducts] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const handleNextImage = () => {
    if (product && product.imageGallery.length > 1) {
      setSelectedImageIndex(
        (prevIndex) => (prevIndex + 1) % product.imageGallery.length
      );
    }
  };

  const handlePrevImage = () => {
    if (product && product.imageGallery.length > 1) {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === 0 ? product.imageGallery.length - 1 : prevIndex - 1
      );
    }
  };

  const handleThumbnailClick = (index, event) => {
    event.preventDefault();
    setSelectedImageIndex(index);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      const docRef = doc(firestore, "products", productId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProduct({ id: docSnap.id, ...docSnap.data() });
      }
    };

    const fetchOtherProducts = async () => {
      const querySnapshot = await getDocs(collection(firestore, "products"));
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOtherProducts(productsData.filter((p) => p.id !== productId));
    };

    fetchProduct();
    fetchOtherProducts();
  }, [productId]);

  return (
    <section className="product-info-card-wrapper">
      {product && (
        <div className="product-info-card">
          <div className="product-info-display">
            <div className="product-info-imgs">
              <div className="product-info-img-display">
                {product.imageGallery.length > 1 && (
                  <button
                    onClick={handlePrevImage}
                    className="product-info-img-nav prev"
                  >
                    &#10094;
                  </button>
                )}
                <img
                  src={product.imageGallery[selectedImageIndex]}
                  alt="Product Display"
                  className="product-info-img-showcase"
                />
                {product.imageGallery.length > 1 && (
                  <button
                    onClick={handleNextImage}
                    className="product-info-img-nav next"
                  >
                    &#10095;
                  </button>
                )}
              </div>
            </div>
            <div className="product-info-content">
              <h2 className="product-info-title">
                {currentLanguage.startsWith("pt")
                  ? product.namePt
                  : product.nameEn}
              </h2>
              <p className="product-info-description">
                {currentLanguage.startsWith("pt")
                  ? product.descPt
                  : product.descEn}
              </p>
              <p className="product-info-price">
                {t("Product.PriceM")} {product.priceS}€
              </p>
              <p className="product-info-price">
                {t("Product.PriceNM")} {product.priceNS}€
              </p>
              <p className="product-info-contact">
                {t("Product.ContactNumber")}: 225 377 699
              </p>
              <button
                className="submit-button"
                onClick={() => setShowForm(true)}
              >
                {t("Product.RequestButton")}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="merchandise-list other-products">
        {otherProducts.map((item) => (
          <div key={item.id} className="merchandise-item">
            <Link to={`/Merchendising/${item.id}`}>
              <img
                src={item.mainImage}
                alt={currentLanguage.startsWith("pt") ? item.namePt : item.nameEn}
                className="merchandise-image"
              />
              <div className="merchandise-details">
                <h2>
                  {currentLanguage.startsWith("pt") ? item.namePt : item.nameEn}
                </h2>
                <Link to={`/Merchendising/${item.id}`} className="read-more-link">
                  {t("Vehicles.Button")}
                </Link>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {showForm && (
        <ProductForm
          onClose={() => setShowForm(false)}
          productName={currentLanguage.startsWith("pt") ? product.namePt : product.nameEn}
        />
      )}
    </section>
  );
};

export default ProductInfo;
