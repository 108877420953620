import React, { useEffect, useRef, useState } from "react";
import "./MemberBenefits.css";
import { useTranslation } from "react-i18next";
import certificationImage from "../../../Images/CPAA_Sao-Martinho-4k-3270.jpg";
import insuranceImage from "../../../Images/1B2.jpg";
import fivaPassportImage from "../../../Images/passaporte_FIVA.jpg";
import consultancyImage from "../../../Images/vistoria.jpg";
import toursImage from "../../../Images/1B4.jpg";
import newsletterImage from "../../../Images/newsletter.jpg";
import whatsappImage from "../../../Images/whatsapp (1).jpg";
import partnershipsImage from "../../../Images/Parcerias-CPAA-Shell (1).jpg";
import { Link } from "react-router-dom";
import MembershipForm from "./MembershipFormClubVantages";

const MemberBenefits = () => {
  const { t } = useTranslation();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  const [showMembershipForm, setShowMembershipForm] = useState(false);
  const benefitRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !benefitRefs.current.includes(el)) {
      benefitRefs.current.push(el);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const currentRefs = benefitRefs.current.filter((el) => el != null);

    currentRefs.forEach((el) => observer.observe(el));

    return () => {
      currentRefs.forEach((el) => observer.unobserve(el));
    };
  }, []);
  const handleBenefitClick = (benefit) => {
    if (benefit.shouldOpenForm) {
      setShowMembershipForm(true);
    }
  };
  const benefits = [
    {
      id: "certification",
      titleKey: "TheClubVantages.TheClubVantagesBenefits2",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits3",
      image: certificationImage,
    },
    {
      id: "insurance",
      titleKey: "TheClubVantages.TheClubVantagesBenefits4",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits5",
      image: insuranceImage,
    },
    {
      id: "consultancy",
      titleKey: "TheClubVantages.TheClubVantagesBenefits8",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits9",
      image: consultancyImage,
    },
    {
      id: "tours",
      titleKey: "TheClubVantages.TheClubVantagesBenefits10",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits11",
      image: toursImage,
    },
    {
      id: "newsletter",
      titleKey: "TheClubVantages.TheClubVantagesBenefits12",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits13",
      image: newsletterImage,
    },
    {
      id: "whatsapp",
      titleKey: "TheClubVantages.TheClubVantagesBenefits14",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits15",
      image: whatsappImage,
    },
    {
      id: "partnerships",
      titleKey: "TheClubVantages.TheClubVantagesBenefits16",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits17",
      image: partnershipsImage,
    },
    {
      id: "fivaPassport",
      titleKey: "TheClubVantages.TheClubVantagesBenefits6",
      descriptionKey: "TheClubVantages.TheClubVantagesBenefits7",
      image: fivaPassportImage,
    },
  ];
  benefits.forEach((benefit) => {
    switch (benefit.id) {
      case "certification":
        benefit.path = "/CertificationsInfo";
        break;
      case "insurance":
        benefit.path = "/InsuranceClassic";
        break;
      case "consultancy":
        benefit.path = "/CLUBEREGISTATION";
        break;
      case "tours":
        benefit.path = "/EventsCalender";
        break;
      case "partnerships":
        benefit.path = "/Partners";
        break;
      case "fivaPassport":
        benefit.path = "/FIVAPASSPORT";
        break;
      case "whatsapp":
        benefit.shouldOpenForm = true;
        break;
    }
  });
  return (
    <div className="member-benefits-wrapper">
      <h2 className="member-benefits-title">
        {t("TheClubVantages.TheClubVantagesBenefits")}
      </h2>
      <div className="member-benefits-container2">
        {benefits.map((benefit, index) => (
          <div
            className={`benefit-item ${
              benefit.shouldOpenForm ? "clickable" : ""
            }`}
            key={benefit.id}
            onClick={() => handleBenefitClick(benefit)}
            ref={(el) => (benefitRefs.current[index] = el)}
          >
            {benefit.path ? (
              <Link to={benefit.path}>
                <img
                  src={benefit.image}
                  alt={t(benefit.titleKey)}
                  className="benefit-image"
                />
              </Link>
            ) : (
              <img
                src={benefit.image}
                alt={t(benefit.titleKey)}
                className="benefit-image"
              />
            )}
            <div className="benefit-content">
              <h3 className="benefit-title">{t(benefit.titleKey)}</h3>
            </div>
          </div>
        ))}
      </div>
      {showMembershipForm && (
        <MembershipForm onClose={() => setShowMembershipForm(false)} />
      )}
      <div className="member-benefits-button-container">
      <Link to="/CLUBEREGISTATION">

        <button className="join">
          {t("Parallax.join")}
        </button>
        </Link>

      </div>
    </div>
  );
};

export default MemberBenefits;
