import React, { useState, useEffect } from 'react';
import EventFilter from './EventFilter/EventFilter';
import Ticket from './EventsCalendar/Ticket';
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase-config";

const EventsContainer = () => {
    const [events, setEvents] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [month, setMonth] = useState('');

    useEffect(() => {
        const fetchEvents = async () => {
            const eventsCollectionRef = collection(firestore, "events");
            const eventsSnapshot = await getDocs(eventsCollectionRef);
            const eventsList = eventsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setEvents(eventsList);
        };

        fetchEvents();
    }, []);

    return (
        <div>
            <EventFilter onYearChange={setYear} onMonthChange={setMonth} />
            <Ticket events={events} year={year} month={month} />
        </div>
    );
};

export default EventsContainer;
