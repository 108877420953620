import React from "react";
import "./PrivacyPolicy.css";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">
        {t("TheClubPolicy.TheClubPolicy")}
      </h1>
      <p>{t("TheClubPolicy.TheClubPolicy3")}</p>
      <p>
        {t("TheClubPolicy.TheClubPolicy4")}{" "}
        <strong>{t("TheClubPolicy.TheClubPolicy5")}</strong>
        {t("TheClubPolicy.TheClubPolicy6")}
      </p>
      <p>{t("TheClubPolicy.TheClubPolicy7")}</p>
      <p>
        {t("TheClubPolicy.TheClubPolicy8")}
        <br />
        {t("TheClubPolicy.TheClubPolicy9")}
        <br />
        {t("TheClubPolicy.TheClubPolicy10")}
        <br />
        {t("TheClubPolicy.TheClubPolicy11")}
      </p>
      <h3 className="privacy-policy-subtitle">
        {t("TheClubPolicy.TheClubPolicy12")}
      </h3>
      <p>{t("TheClubPolicy.TheClubPolicy13")}</p>
      <h4 className="privacy-policy-section-title">
        {t("TheClubPolicy.TheClubPolicy14")}
      </h4>
      <p>{t("TheClubPolicy.TheClubPolicy15")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy16")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy17")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy18")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy19")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy20")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy21")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy22")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy23")}</p>
      <h4 className="privacy-policy-section-title">
        {t("TheClubPolicy.TheClubPolicy24")}
      </h4>
      <p>{t("TheClubPolicy.TheClubPolicy25")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy26")}</p>

      <p>{t("TheClubPolicy.TheClubPolicy27")}</p>

      <p>{t("TheClubPolicy.TheClubPolicy28")}</p>
      <h3 className="privacy-policy-subtitle">
        {t("TheClubPolicy.TheClubPolicy29")}
      </h3>
      <p>{t("TheClubPolicy.TheClubPolicy30")}</p>
      <ul>
        <li>{t("TheClubPolicy.TheClubPolicy31")}</li>
        <li>{t("TheClubPolicy.TheClubPolicy32")}</li>
      </ul>
      <p>{t("TheClubPolicy.TheClubPolicy33")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy34")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy35")}</p>
      <h3 className="privacy-policy-subtitle">
        {t("TheClubPolicy.TheClubPolicy36")}
      </h3>
      <p>{t("TheClubPolicy.TheClubPolicy37")}</p>
      <ul>
        <li>{t("TheClubPolicy.TheClubPolicy38")}</li>
        <li>{t("TheClubPolicy.TheClubPolicy39")}</li>
        <li>{t("TheClubPolicy.TheClubPolicy40")}</li>
        <li>{t("TheClubPolicy.TheClubPolicy41")}</li>
      </ul>
      <p>{t("TheClubPolicy.TheClubPolicy42")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy43")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy44")}</p>
      <p>
        {t("TheClubPolicy.TheClubPolicy45")}
        <a
          href="https://www.cnpd.pt/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("TheClubPolicy.TheClubPolicy46")}
        </a>
        .
      </p>
      <h4 className="privacy-policy-section-title">
        {t("TheClubPolicy.TheClubPolicy47")}
      </h4>
      <p>{t("TheClubPolicy.TheClubPolicy48")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy49")}</p>
      <h4 className="privacy-policy-section-title">
        {t("TheClubPolicy.TheClubPolicy50")}
      </h4>
      <p>{t("TheClubPolicy.TheClubPolicy51")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy52")}</p>

      <p>{t("TheClubPolicy.TheClubPolicy53")}</p>

      <p>{t("TheClubPolicy.TheClubPolicy54")}</p>

      <p>{t("TheClubPolicy.TheClubPolicy55")}</p>

      <p>{t("TheClubPolicy.TheClubPolicy56")}</p>
      <h4 className="privacy-policy-section-title">
        {t("TheClubPolicy.TheClubPolicy57")}
      </h4>
      <p>{t("TheClubPolicy.TheClubPolicy58")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy59")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy60")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy61")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy62")}</p>
      <h4 className="privacy-policy-section-title">
        {t("TheClubPolicy.TheClubPolicy63")}
      </h4>
      <p>{t("TheClubPolicy.TheClubPolicy64")}</p>
      <h4 className="privacy-policy-section-title">
        {t("TheClubPolicy.TheClubPolicy65")}
      </h4>
      <p>{t("TheClubPolicy.TheClubPolicy66")}</p>
      <h3 className="privacy-policy-subtitle">
        {t("TheClubPolicy.TheClubPolicy67")}
      </h3>
      <p>{t("TheClubPolicy.TheClubPolicy68")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy69")}</p>
      <h3 className="privacy-policy-subtitle">
        {t("TheClubPolicy.TheClubPolicy70")}
      </h3>
      <p>{t("TheClubPolicy.TheClubPolicy71")}</p>
      <ul>
        <li>{t("TheClubPolicy.TheClubPolicy72")}</li>
        <li>{t("TheClubPolicy.TheClubPolicy73")}</li>
      </ul>
      <p>{t("TheClubPolicy.TheClubPolicy74")}</p>
      <p>{t("TheClubPolicy.TheClubPolicy75")}</p>
    </div>
  );
};

export default PrivacyPolicy;
