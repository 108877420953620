import React, { useState } from "react";
import leftImage from "../../Images/logo.png";
import "./MobileNavbar.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MobileNavbar = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isLoginActive, setIsLoginActive] = useState(false);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "pt" : "en";
    setLanguage(newLanguage);
    changeLanguage(newLanguage);
  };
  const toggleLogin = () => {
    setIsLoginActive(!isLoginActive);
  };
  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (index) => {
    if (activeDropdownIndex === index) {
      setActiveDropdownIndex(null);
    } else {
      setActiveDropdownIndex(index);
    }
  };
  const menuClass = `menu ${isMenuOpen ? "open" : "flex"}`;

  return (
    <>
      <header className="headerMobile sticky">
        <div className="container">
          <nav className={menuClass}>
            <div className="head"></div>
            <ul>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 1 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(1)}
              >
                <a href="#">{t("header.CLUB")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/CLUBE">
                      <span>{t("header.THECLUB")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEVANTAGES">
                      <span>{t("header.MEMBERADVANTAGES")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEREGISTATION">
                      <span>{t("header.MEMBERREGISTRATION")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEREPORT">
                      <span>{t("header.ACCOUNTSREPORT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CLUBEPOLICY">
                      <span>{t("header.PRIVACYPOLICY")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 2 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(2)}
              >
                <a href="#">{t("header.FIVA")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/FIVAORGANIZATION">
                      <span>{t("header.ORGANIZATIONANDOBJECTIVES")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/FIVALETTER">
                      <span>{t("header.TURINLETTER")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/FIVAGUIDE">
                      <span>{t("header.FIVAGUIDE")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/FIVAPASSPORT">
                      <span>{t("header.FIVAPASSPORT")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 3 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(3)}
              >
                <a href="#">{t("header.CERTIFICATIONS")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/CertificationsInfo">
                      <span>{t("header.INFORMATIONS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsConditions">
                      <span>{t("header.NECESSARYCONDITIONS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsBooking">
                      <span>{t("header.MARKING")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsCouncil">
                      <span>{t("header.TECHNICALCOUNCIL")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/CertificationsCalendar">
                      <span>{t("header.CERTIFICATIONCALENDAR")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 4 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(4)}
              >
                <a href="#">{t("header.SEASONREGISTRATION")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/MembershipInformation">
                      <span>{t("header.INFORMATIONS")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 5 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(5)}
              >
                <a href="#">{t("header.INSURANCE")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/InsuranceClassic">
                      <span>{t("header.CLASSICS")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/InsurancePreClassic">
                      <span>{t("header.PRE-CLASSICS")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`dropdown ${
                  activeDropdownIndex === 6 ? "active" : ""
                }`}
                onClick={() => toggleDropdown(6)}
              >
                <a href="#">{t("header.EVENTS")}</a>
                <i className="fa-solid fa-chevron-down"></i>
                <ul className="sub-menu">
                  <li>
                    <Link to="/EventsCalender">
                      <span>{t("header.CPAAEVENTSCALENDAR")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/EventsCalendarExpired">
                      <span>{t("header.OTHEREVENTSHELD")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/InternationalEvents">
                      <span>{t("header.INTERNATIONEVENTS")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/Merchendising">{t("header.MERCHANDISING")} </Link>
              </li>
              <li>
                <Link to="/Vehicles">{t("header.RANKED")} </Link>
              </li>
              <li>
                <Link to="/Partners">{t("header.PARTNERSHIPS")} </Link>
              </li>
            </ul>
          </nav>
          <div className="header-right">
            <button
              type="button"
              className={`open-menu-btn ${isMenuOpen ? "active" : ""}`}
              onClick={toggleMenu}
            >
              <span className="line line-1"></span>
              <span className="line line-2"></span>
              <span className="line line-3"></span>
            </button>
          </div>
          <Link to="/">
            <img src={leftImage} alt="Left Logo" />
          </Link>{" "}
          <div className="headerMobile__language-toggle" onClick={toggleLanguage}>
            <span className={`headerMobile__language-toggle-item ${language === "pt" ? "headerMobile__language-toggle-item--active" : ""}`}>PT</span>
            <span> | </span>
            <span className={`headerMobile__language-toggle-item ${language === "en" ? "headerMobile__language-toggle-item--active" : ""}`}>EN</span>
          </div>
        </div>
      </header>
    </>
  );
};

export default MobileNavbar;
