import React from "react";
import "./ImageComponent3.css";
import localImage from "../../../Images/10.jpg";

const ImageComponent = () => {
  return (
    <div className="image-container3">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
