import React, { useState } from "react";
import "./MembershipInfo.css";
import { useTranslation } from "react-i18next";
import carIcon from "../../../Images/car.png";
import motorcycleIcon from "../../../Images/motorcycle.png";
import LicensePlateForm from "./LicensePlateForm";

const PriceCard = ({ title, prices, iconSrc }) => (
  <div className="price-card">
    <h3 className="price-card-title">{title}</h3>
    {prices.map((price, index) => (
      <div key={index} className="price-item">
        <img src={iconSrc[index]} alt="icon" className="price-icon" />
        <span className="price-highlight">{price}</span>
      </div>
    ))}
  </div>
);

const MembershipInfo = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const memberPrices = [
    t("MatriculationInfo.MatriculationInfo14"),
    t("MatriculationInfo.MatriculationInfo15"),
  ];

  const nonMemberPrices = [
    t("MatriculationInfo.MatriculationInfo17"),
    t("MatriculationInfo.MatriculationInfo18"),
  ];

  const [showForm, setShowForm] = useState(false);

  return (
    <div className="membership-infoMatriculation">
      <br></br>
      <h2 className="membership-titleMatriculation">
        {t("MatriculationInfo.MatriculationInfo")}
      </h2>
      <p>{t("MatriculationInfo.MatriculationInfo2")}</p>
      <br></br>

      <p>{t("MatriculationInfo.MatriculationInfo3")}</p>
      <br></br>

      <h3 className="membership-subtitleMatriculation">
        {t("MatriculationInfo.MatriculationInfo4")}
      </h3>
      
      <p>{t("MatriculationInfo.MatriculationInfo5")}</p>
      <br></br>

      <ul className="membership-listMatriculation">
        <li>{t("MatriculationInfo.MatriculationInfo6")}</li>
        <li>{t("MatriculationInfo.MatriculationInfo7")}</li>
        <li>{t("MatriculationInfo.MatriculationInfo8")}</li>
        <li>{t("MatriculationInfo.MatriculationInfo9")}</li>
      </ul>
      <br></br>

      <p>{t("MatriculationInfo.MatriculationInfo10")}</p>
      <br></br>

      <h3 className="membership-subtitleMatriculation">
        {t("MatriculationInfo.MatriculationInfo11")}
      </h3>

      <div className="fiva-description-container">
        <div className="prices-container">
          <PriceCard
            title={t("CerteficationsInfo.CerteficationsInfo3")}
            prices={memberPrices}
            iconSrc={[carIcon, motorcycleIcon]}
          />
          <PriceCard
            title={t("CerteficationsInfo.CerteficationsInfo6")}
            prices={nonMemberPrices}
            iconSrc={[carIcon, motorcycleIcon]}
          />
        </div>
      </div>
      <button
        className="join-button"
        onClick={() => setShowForm(true)}
      >
        {t("LicensePlateForm.RequestButton")}
      </button>
      {showForm && <LicensePlateForm onClose={() => setShowForm(false)} />}
    </div>
  );
};

export default MembershipInfo;
