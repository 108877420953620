import React from "react";
import "./PageInConstruction.css";
import localImage from "../../Images/PageInConstruction.webp";

const PageInConstruction = () => {
  const imagePath = `${process.env.PUBLIC_URL}/your-image-name.png`;

  return (
    <div className="page-in-construction-container">
      <img
        src={localImage}
        alt="Page Still In Construction"
        className="construction-image"
      />
    </div>
  );
};

export default PageInConstruction;
