import React from "react";
import "./FivaDescription.css";
import { useTranslation } from "react-i18next";

const FivaDescription = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="fiva-description-container6">
      <h1 className="fiva-description-title6">
        {t("FivaDescriptionPass.FivaDescriptionPass")}
      </h1>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass2")}</p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass3")}</p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass4")}</p>
      <p>
        <strong>{t("FivaDescriptionPass.FivaDescriptionPass5")}</strong>
      </p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass6")}</p>
      <p>
        <strong>{t("FivaDescriptionPass.FivaDescriptionPass7")}</strong>
      </p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass8")}</p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass9")}</p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass10")}</p>
      <p>
        <strong>{t("FivaDescriptionPass.FivaDescriptionPass11")}</strong>
      </p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass12")}</p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass13")}</p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass14")}</p>
      <p>
        <strong>{t("FivaDescriptionPass.FivaDescriptionPass15")}</strong>
      </p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass16")}</p>
      <p>
        <strong>{t("FivaDescriptionPass.FivaDescriptionPass17")}</strong>
      </p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass18")}</p>
      <p>
        <strong>{t("FivaDescriptionPass.FivaDescriptionPass19")}</strong>
      </p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass20")}</p>
      <p>
        <strong>{t("FivaDescriptionPass.FivaDescriptionPass21")}</strong>
      </p>
      <p>{t("FivaDescriptionPass.FivaDescriptionPass22")}</p>
      <p>
        <a
          href="https://applications.fiva.org/?_gl=1*d73hbb*_ga*MTg4Mzg4MzU3MS4xNjkzNTc1ODA2*_ga_MH1PHQKFNV*MTcwNjcyMDc0Ny44LjAuMTcwNjcyMDc0Ny4wLjAuMA.."
          target="_blank"
          rel="noopener noreferrer"
          className="join-button"
        >
          {t("FivaDescriptionPass.FivaDescriptionPass24")}
        </a>
      </p>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default FivaDescription;
