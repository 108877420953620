import React from "react";
import Footer from "./Geral/Footer";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";
import CertificationsConditions from "./Certifications/CertificationsConditions/CertificationsConditions";
import ParentComponent from "./Geral/ParentComponent";
import AdditionalInfo from "./Club/TheClubVantages/AdditionalInfo";
import ImageComponent from "./Certifications/CertificationsConditions/ImageComponentConditions";
const App = () => {
  return (
    <div>
      <ParentComponent />
      <ImageComponent />
      <CertificationsConditions />
      <SocialMediaButtons />
      <AdditionalInfo />
      <Footer />
    </div>
  );
};

export default App;
