import React from "react";
import "./ImageComponentTurin.css";
import localImage from "../../../Images/FIVA-charter-of-turin-handbook.webp";

const ImageComponent = () => {
  return (
    <div className="image-container4">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
