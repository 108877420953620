import React from 'react';

const RandomPageImageBlock = ({ image }) => (
    <div className="random-page-image-block">
        <div className="aspect-ratio-box">
            <img src={image} alt="Decorative" className="random-page-image" />
        </div>
    </div>
);

export default RandomPageImageBlock;
