import React, { useEffect } from "react";
import "./ParallaxSection.css";
import backgroundImage from "../../Images/23.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ParallaxSection = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const parallaxImage = document.querySelector(".parallax-image");

      if (parallaxImage) {
        const scrollPosition = window.pageYOffset;
        const imageTop = Math.min(-50 + scrollPosition * 0.5, 0);
        parallaxImage.style.top = `${imageTop}vh`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="parallax-container">
      <div
        className="parallax-image"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="overlayParallax" /> {}
      <div className="content-containerParallax">
        <h1 className="titleParallax">{t("Parallax.title")}</h1>
        <Link to="/CLUBEREGISTATION">
          <button className="join-button-Parallax">{t("Parallax.join")}</button>
        </Link>
      </div>
    </div>
  );
};

export default ParallaxSection;
