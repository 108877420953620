import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import "./ProductsList.css";
import { useTranslation } from "react-i18next";

const ProductsList = () => {
  const [merchandise, setMerchandise] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    const fetchMerchandise = async () => {
      const querySnapshot = await getDocs(collection(firestore, "products"));
      const merchandiseData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMerchandise(merchandiseData);
    };

    fetchMerchandise();
  }, []);

  return (
    <div className="merchandise-container">
      <div className="vehicles-list-title">
        <h1>{t("Merchandise.Title")}</h1>
      </div>{" "}
      {/* Added title */}
      <div className="merchandise-list">
        {merchandise.map((item) => (
                        <Link to={`/Merchendising/${item.id}`}>

          <div key={item.id} className="merchandise-item">

            <img
              src={item.mainImage}
              alt={currentLanguage.startsWith("pt") ? item.namePt : item.nameEn}
              className="merchandise-image"
            />
            <div className="merchandise-details">
              <h2>
                {currentLanguage.startsWith("pt") ? item.namePt : item.nameEn}
              </h2>
              <Link to={`/Merchendising/${item.id}`} className="read-more-link">
                {t("Vehicles.Button")}
              </Link>
            </div>
          </div>
          </Link>

        ))}
      </div>
    </div>
  );
};

export default ProductsList;
