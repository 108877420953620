import React from "react";
import "./FivaLetterDescription.css";
import pdf from "../../../PDFs/FIVA_Turin_charter_handbook_intro.pdf";
import { useTranslation } from "react-i18next";

const FivaLetterDescription = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="fiva-letter-description-container">
      <h1 className="fiva-letter-description-title">{t("Turim.Turim")}</h1>
      <p>{t("Turim.Turim2")}</p>
      <br></br>
      <p>{t("Turim.Turim3")}</p>

      <br></br>

      <p>
        <strong>{t("Turim.Turim4")}</strong>
      </p>
      <p>{t("Turim.Turim5")}</p>
      <br></br>

      <p>
        <strong>{t("Turim.Turim6")}</strong>
      </p>
      <p>{t("Turim.Turim7")}</p>
      <p>{t("Turim.Turim8")}</p>
      <p>{t("Turim.Turim9")}</p>
      <p>{t("Turim.Turim10")}</p>
      <p>{t("Turim.Turim11")}</p>
      <p>{t("Turim.Turim12")}</p>
      <br></br>

      <p>
        <strong>{t("Turim.Turim13")}</strong>
      </p>
      <p>{t("Turim.Turim14")}</p>
      <p>{t("Turim.Turim15")}</p>
      <br></br>

      <p>
        <strong>{t("Turim.Turim16")}</strong>
      </p>
      <p>{t("Turim.Turim17")}</p>
      <p>{t("Turim.Turim18")}</p>
      <p className="document-link-container">
        <a href={pdf} target="_blank" rel="noopener noreferrer">
          {t("Turim.Turim19")}
        </a>
      </p>
    </div>
  );
};

export default FivaLetterDescription;
