import React from 'react';
import './ImageComponent1.css'; 
import localImage from '../../../Images/CPAA-Inverno-4k-2-4.jpg';

const ImageComponent = () => {
  return (
    <div className="image-container-Policy">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
