import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  const handleScrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  const heroImageURL = "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/VideoHero%2F1B.jpg?alt=media&token=3a4b0ea7-4945-4780-938d-d23905f4afe4";
  const rightImageURL = "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/VideoHero%2FFivaLogoOficial.png?alt=media&token=a8e7ca78-035c-4a1e-9fb9-e5c1d4ec43a9";

  return (
    <div className="hero-container">
      <div className="background-image-container">
        <img className="background-image" src={heroImageURL} alt="Hero Background" />
      </div>
      <img src={rightImageURL} alt="Top Right" className="top-right-image" />
      <div className="overlay"></div>
      <div className="arrow-container" onClick={handleScrollDown}>
        <div className="arrow"></div>
      </div>
    </div>
  );
};

export default HeroSection;
