import React from "react";
import Footer from "./Geral/Footer";
import ProductsList from "./Merchandising/ProductsList";
import ParentComponent from "./Geral/ParentComponent";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";

const App = () => {
  return (
    <div>
      <ParentComponent />
      <ProductsList />
      <SocialMediaButtons />
      <Footer />
    </div>
  );
};

export default App;
