import React from "react";
import Footer from "./Geral/Footer";
import ImageComponent from "./Club/TheClub/ImageComponent";
import ClubInfo from "./Club/TheClub/ClubInfo";
import TeamSection from "./Club/TheClub/TeamSection";
import ClubInfoStatus from "./Club/TheClub/ClubInfoStatus";
import ParentComponent from "./Geral/ParentComponent";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";

const App = () => {
  return (
    <div>
      <ParentComponent />
      <ImageComponent />
      <SocialMediaButtons />
      <ClubInfo />
      <TeamSection />
      <ClubInfoStatus />
      <Footer />
    </div>
  );
};

export default App;
