import React from "react";
import "./ImageComponent8.css";
import localImage from "../../../Images/IMG_5055-2.jpg";

const ImageComponent = () => {
  return (
    <div className="image-container8">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
