import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import userIcon from "../../Images/people.png";
import emailIcon from "../../Images/email.png";
import phoneIcon from "../../Images/smartphone.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const ProductForm = ({ onClose, productName }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    formType: "productRequest",
    productName: productName // Add the product name to formData
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailContent = `
      <div style="font-family: Arial, sans-serif; line-height: 1.6;">
        <h2 style="color: #333;">${t("Product.RequestFormTitle")}</h2>
        <p><strong>${t("Product.NamePlaceholder")}:</strong> ${formData.name}</p>
        <p><strong>${t("Product.EmailPlaceholder")}:</strong> <a href="mailto:${formData.email}">${formData.email}</a></p>
        <p><strong>${t("Product.PhonePlaceholder")}:</strong> ${formData.phone}</p>
        <p><strong>${t("Product.ProductName")}:</strong> ${formData.productName}</p>
      </div>
    `;

    try {
      const response = await fetch("https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          emailContent
        }),
      });

      if (response.ok) {
        alert(t("Form submitted successfully!"));
        onClose(); // Close the form after successful submission
      } else {
        alert(t("Failed to submit the form."));
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert(t("Product.ErrorMessage"));
    }
  };

  return (
    <div className="membership-form-overlay">
      <div className="membership-form-container">
        <div className="membership-form-header">
          <h2 className="membership-form-title">
            {t("Product.RequestFormTitle")}
          </h2>
          <button
            className="close-membership-form"
            onClick={onClose}
            aria-label="Close form"
          >
            ✕
          </button>
        </div>
        <form className="membership-form" onSubmit={handleSubmit}>
          <p className="input-label">
            {t("Product.Label")}
          </p>

          <div className="form-group with-icon">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder={t("Product.NamePlaceholder")}
              value={formData.name}
              onChange={handleChange}
              required
            />
            <img src={userIcon} alt="User Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder={t("Product.EmailPlaceholder")}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <img src={emailIcon} alt="Email Icon" className="input-icon" />
          </div>
          <div className="form-group phone-input-group">
            <PhoneInput
              international
              defaultCountry="PT"
              value={formData.phone}
              onChange={(phone) => setFormData((prevData) => ({ ...prevData, phone }))}
              className="my-phone-input"
              required
            />
            <img src={phoneIcon} alt="Phone Icon" className="input-icon" />
          </div>
          <div className="form-group">
            <button type="submit" className="submit-button">
              {t("Product.SubmitButton")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductForm;
