import React from "react";
import "./FivaDescription.css";
import { useTranslation } from "react-i18next";

const FivaDescription = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <div className="fiva-description-container-description">
      <h1 className="fiva-description-title-C">
        {t("FivaDescription.FivaDescription")}
      </h1>
      <p>{t("FivaDescription.FivaDescription2")}</p>
      <p>{t("FivaDescription.FivaDescription3")}</p>
      <p>{t("FivaDescription.FivaDescription4")}</p>
      <p>{t("FivaDescription.FivaDescription5")}</p>
      <p>{t("FivaDescription.FivaDescription6")}</p>

      {}

      <h2> {t("FivaDescription.FivaDescription7")}</h2>
      {}
      <p>{t("FivaDescription.FivaDescription8")}</p>

      <h2> {t("FivaDescription.FivaDescription9")}</h2>
      {}
      <p>{t("FivaDescription.FivaDescription10")}</p>

      <h2> {t("FivaDescription.FivaDescription11")}</h2>
      {}
      <p>{t("FivaDescription.FivaDescription12")}</p>
    </div>
  );
};

export default FivaDescription;
