import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { firestore } from "../../../firebase-config";
import { doc, getDoc } from "firebase/firestore";

const InternationalTicket = ({ events, year, month }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [articles, setArticles] = useState({});

  useEffect(() => {
    const fetchArticleTitles = async () => {
      const articlesData = {};
      for (const event of events) {
        if (event.articleId) {
          const docRef = doc(firestore, "articles", event.articleId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            articlesData[event.articleId] = docSnap.data().titlePt;
          }
        }
      }
      setArticles(articlesData);
    };

    fetchArticleTitles();
  }, [events]);

  const getMonthName = (monthIndex) => {
    const monthNames = t("months", { returnObjects: true });
    return Object.values(monthNames)[monthIndex];
  };

  const isWithinFilter = (eventDate, filterYear, filterMonth) => {
    if (!eventDate) return false;

    const [eventYear, eventMonth] = eventDate.split("-");

    if (filterYear && eventYear !== filterYear) {
      return false;
    }

    const eventMonthName = getMonthName(parseInt(eventMonth, 10) - 1);
    const filterMonthName = getMonthName(parseInt(filterMonth, 10) - 1);
    if (filterMonth && eventMonthName !== filterMonthName) {
      return false;
    }

    return true;
  };

  const createDateFromEvent = (eventDate) => {
    const [year, month, day] = eventDate.split("-");
    return new Date(year, month - 1, day);
  };

  const filteredAndSortedEvents = events
    .filter(event => event.eventType === "international") // Filter to show only international events
    .filter(event =>
      isWithinFilter(event.startDate, year, month) ||
      isWithinFilter(event.endDate, year, month))
    .sort((a, b) => createDateFromEvent(a.startDate) - createDateFromEvent(b.startDate)); // Sort chronologically

  const formatEventDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startDay = start.getDate();
    const endDay = end.getDate();
    const startMonth = getMonthName(start.getMonth());
    const endMonth = getMonthName(end.getMonth());

    if (startDate === endDate) {
      return (
        <>
          <div className="event-date-day">{startDay}</div>
          <div className="event-date-month">{startMonth}</div>
        </>
      );
    } else if (startMonth === endMonth) {
      return (
        <>
          <div className="event-date-day">{`${startDay}-${endDay}`}</div>
          <div className="event-date-month">{startMonth}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="event-date-day">{startDay}</div>
          <div className="event-date-month">{startMonth}</div>
          <div className="event-date-day">{endDay}</div>
          <div className="event-date-month">{endMonth}</div>
        </>
      );
    }
  };

  return (
    <div className="events-list-container">
      {filteredAndSortedEvents.length > 0 ? (
        filteredAndSortedEvents.map((event) => (
          <div key={event.id} className="event-card">
            <div className="event-image-container">
              <img
                src={event.mainImage}
                alt={currentLanguage === "pt" ? event.titlePt : event.titleEn}
                className="event-image"
              />
              <div className="event-date-container">
                {formatEventDate(event.startDate, event.endDate)}
              </div>
              {event.articleId && articles[event.articleId] && (
                <Link to={`/article/${encodeURIComponent(articles[event.articleId])}`} className="event-article-button">
                  ➔
                </Link>
              )}
            </div>
            <div className="event-title">
              {currentLanguage === "pt" ? event.titlePt : event.titleEn}
            </div>
          </div>
        ))
      ) : (
        <div className="no-events-message">
          {t("Events.noEventsThisMonth")}{" "}
        </div>
      )}
    </div>
  );
};

export default InternationalTicket;
