import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./AnimatedBoxesNews.css";
import { firestore } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";

const AnimatedBoxNews = ({ name, image, titlePt, createdAt, t }) => {
  // Replace spaces with underscores in titlePt
  const sanitizedTitle = titlePt.replace(/ /g, '_');

  // Create a unique slug by combining sanitized titlePt and the timestamp
  const uniqueSlug = `${encodeURIComponent(sanitizedTitle)}-${createdAt}`;

  return (
    <div className="card-container1">
      <div className="image-container">
        <img src={image} alt={name} className="article-image" />
      </div>
      <div className="text-content1">
        <h3>{name}</h3>
        <Link to={`/article/${uniqueSlug}`} className="article-read-more">
          {t("TheClubVantages.readMore")}
        </Link>
      </div>
    </div>
  );
};

const AnimatedBoxesNews = () => {
  const [articles, setArticles] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "articles"));
        const fetchedArticles = querySnapshot.docs
          .map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              createdAt: data.createdAt.toMillis(), // Convert Firestore timestamp to milliseconds
            };
          })
          .filter((article) => article.displayOnHomepage)
          .sort((a, b) => b.topArticle - a.topArticle)
          .slice(0, 9);

        setArticles(fetchedArticles);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="animated-news-section">
      <div className="animated-boxes-container1">
        {articles.map((article) => (
          <AnimatedBoxNews
            key={article.id}
            name={i18n.language === "en" ? article.titleEn : article.titlePt}
            image={article.mainImage}
            titlePt={article.titlePt}
            createdAt={article.createdAt} // Pass createdAt to AnimatedBoxNews component
            t={t}
          />
        ))}
      </div>
      <div className="more-news-button-container">
        <Link to="/Articles" className="more-news-button">
          {t("buttons.MoreNews")}
        </Link>
      </div>
    </div>
  );
};

export default AnimatedBoxesNews;
