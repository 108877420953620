import React from "react";
import "./ImageComponentBookng.css";
import localImage from "../../../Images/CPAA-Passeio-Jovens-24-4k-5188.jpg";
import { useTranslation } from "react-i18next";

const ImageComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-container-booking">
      <img
        src={localImage}
        alt="Join the Classic Car Club"
        className="banner-image-booking"
      />
      <div className="banner-overlay"></div> {}
      <div className="banner-text"></div>
    </div>
  );
};

export default ImageComponent;
