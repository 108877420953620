import React from "react";
import "./ImageComponent1.css";
import localImage from "../../../Images/A4-172.jpg";

const ImageComponent = () => {
  return (
    <div className="image-container1">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
