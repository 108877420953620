import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import userIcon from "../../../Images/people.png";
import emailIcon from "../../../Images/email.png";
import phoneIcon from "../../../Images/smartphone.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const LicensePlateForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    brand: "",
    model: "",
    year: "",
    formType: "licensePlateRequest"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        onClose();
      } else {
        const errorText = await response.text();
        console.error("Failed to submit the form:", errorText);
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="membership-form-overlay">
      <div className="membership-form-container">
        <div className="membership-form-header">
          <h2 className="membership-form-title">
            {t("LicensePlateForm.RequestFormTitle")}
          </h2>
          <button
            className="close-membership-form"
            onClick={onClose}
            aria-label="Close form"
          >
            ✕
          </button>
        </div>
        <form className="membership-form" onSubmit={handleSubmit}>
          <p className="input-label">
            {t("LicensePlateForm.Label")}
          </p>

          <div className="form-group with-icon">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder={t("LicensePlateForm.NamePlaceholder")}
              value={formData.name}
              onChange={handleChange}
              required
            />
            <img src={userIcon} alt="User Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder={t("LicensePlateForm.EmailPlaceholder")}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <img src={emailIcon} alt="Email Icon" className="input-icon" />
          </div>
          <div className="form-group phone-input-group">
            <PhoneInput
              international
              defaultCountry="PT"
              value={formData.phone}
              onChange={(phone) => setFormData((prevData) => ({ ...prevData, phone }))}
              className="my-phone-input"
              required
            />
            <img src={phoneIcon} alt="Phone Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="brand"
              className="form-control"
              placeholder={t("LicensePlateForm.BrandPlaceholder")}
              value={formData.brand}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="model"
              className="form-control"
              placeholder={t("LicensePlateForm.ModelPlaceholder")}
              value={formData.model}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              name="year"
              className="form-control"
              placeholder={t("LicensePlateForm.YearPlaceholder")}
              value={formData.year}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="submit-button">
              {t("LicensePlateForm.SubmitButton")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LicensePlateForm;
