import React, { useState } from "react";
import "./CertificationsBookingInfo.css";
import { useTranslation } from "react-i18next";
import MembershipForm from "./MembershipFormCertefications";

const CertificationsBookingInfo = () => {
  const { t, i18n } = useTranslation();
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  return (
    <div className="certifications-info-container">
      <h1 className="certifications-info-title">
        {t("CertificationsBookingInfo.CertificationsBookingInfo")}
      </h1>
      <br></br>
      <p>{t("CertificationsBookingInfo.CertificationsBookingInfo2")}</p>
      <br></br>
      <h2 className="certifications-info-heading">
        {t("CertificationsBookingInfo.CertificationsBookingInfo3")}
      </h2>
      <p>{t("CertificationsBookingInfo.CertificationsBookingInfo4")}</p>

      <br></br>
      <h2 className="certifications-info-heading">
        {t("CertificationsBookingInfo.CertificationsBookingInfo5")}
      </h2>
      <p>{t("CertificationsBookingInfo.CertificationsBookingInfo6")}</p>

      <br></br>
      <h2 className="certifications-info-heading">
        {t("CertificationsBookingInfo.CertificationsBookingInfo7")}
      </h2>
      <p>{t("CertificationsBookingInfo.CertificationsBookingInfo8")}</p>
      <p>{t("CertificationsBookingInfo.CertificationsBookingInfo9")}</p>

      <br></br>
      <h2 className="certifications-info-heading">
        {t("CertificationsBookingInfo.CertificationsBookingInfo10")}
      </h2>
      <p>{t("CertificationsBookingInfo.CertificationsBookingInfo11")}</p>

      <br></br>
      <h2 className="certifications-info-heading">
        {t("CertificationsBookingInfo.CertificationsBookingInfo12")}
      </h2>
      <p>{t("CertificationsBookingInfo.CertificationsBookingInfo13")}</p>
      <button
        className="join-button"
        onClick={() => setShowMembershipForm(true)}
      >
        {t("CertificationsBookingInfo.CertificationsBookingInfo14")}
      </button>
      {showMembershipForm && (
        <MembershipForm onClose={() => setShowMembershipForm(false)} />
      )}
    </div>
  );
};

export default CertificationsBookingInfo;
