import React from "react";
import "./ImageComponent7.css";
import localImage from "../../../Images/not-5.jpg";

const ImageComponent = () => {
  return (
    <div className="image-container7">
      <img src={localImage} alt="Descriptive Alt Text" />
    </div>
  );
};

export default ImageComponent;
