import React, { useState } from "react";
import "./MembershipInfo.css";
import { useTranslation } from "react-i18next";
import MembershipForm from "./MembershipForm";

// Import the PDF files
import pdfOne from "../../../PDFs/CGerais-Automovel-1.pdf";
import pdfTwo from "../../../PDFs/Caravela Assistência Viagem_Veículos Clássicos.pdf";

const MembershipInfo = () => {
  const { t } = useTranslation();
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  return (
    <div className="membership-infoPreClassic">
      <br></br>
      <h2 className="membership-titlePreClassic">
        {t("InsurancePreClassic.InsurancePreClassic")}
      </h2>
      <p>{t("InsurancePreClassic.InsurancePreClassic2")}</p>
      <br></br>
      <h3 className="membership-subtitlePreClassic">
        {t("InsurancePreClassic.InsurancePreClassic5")}
        <br></br>
      </h3>
      <p>{t("InsurancePreClassic.InsurancePreClassic6")}</p>
      <br></br>
      <p>{t("InsurancePreClassic.InsurancePreClassic7")}</p> <br></br>
      <p>{t("InsurancePreClassic.InsurancePreClassic8")}</p> <br></br>
      <p>
        <strong>{t("InsurancePreClassic.InsurancePreClassic9")}</strong>
      </p>{" "}
      <br></br>
      <p>{t("InsurancePreClassic.InsurancePreClassic10")}</p>
      <p>{t("InsurancePreClassic.InsurancePreClassic11")}</p> <br></br>
      <p>{t("InsurancePreClassic.InsurancePreClassic12")}</p> <br></br>
      <p>
        <strong>{t("InsurancePreClassic.InsurancePreClassic13")}</strong>{" "}
        {t("InsurancePreClassic.InsurancePreClassic14")}
      </p>{" "}
      <br></br>
      <h3 className="membership-subtitlePreClassic">
        {t("InsuranceClassic.InsuranceClassic28")}
      </h3>
      <br></br>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic29")}</strong>
        {t("InsuranceClassic.InsuranceClassic30")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic31")}</strong>
        {t("InsuranceClassic.InsuranceClassic32")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic33")}</strong>
        {t("InsuranceClassic.InsuranceClassic34")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic35")}</strong>
        {t("InsuranceClassic.InsuranceClassic36")}
      </p>
      <p>
        {t("InsuranceClassic.InsuranceClassic37")}{" "}
        <a
          href={pdfOne}
          target="_blank"
          rel="noopener noreferrer"
          className="clickable-link"
        >
          {t("InsuranceClassic.InsuranceClassic38")}
        </a>{" "}
        {t("InsuranceClassic.InsuranceClassic39")}{" "}
        <a
          href={pdfTwo}
          target="_blank"
          rel="noopener noreferrer"
          className="clickable-link"
        >
          {t("InsuranceClassic.InsuranceClassic40")}
        </a>
      </p>
      <button
        className="join-button"
        onClick={() => setShowMembershipForm(true)}
      >
        {t("TheClubRegistationForm.TheClubRegistationForm67")}{" "}
      </button>
      {showMembershipForm && (
        <MembershipForm onClose={() => setShowMembershipForm(false)} />
      )}
    </div>
  );
};

export default MembershipInfo;
