import React from "react";
import "./AffiliatesSection.css";
import logo1 from "../../Images/ENIF_Logotipo.png";
import logo2 from "../../Images/filintomota_logo.png";
import logo3 from "../../Images/Grande-Logo-Murganheira-Sombra.png";
import logo4 from "../../Images/LIQUI MOLY logo@4x.png";

import { useTranslation } from "react-i18next";

const AffiliatesSection = () => {
  const { t } = useTranslation();

  return (
    <div className="affiliates-container">
      <div className="affiliates-header">
        <h2>{t("Affiliate.title")}</h2>
      </div>
      <div className="affiliates-logos">
        <img src={logo1} alt="Company Logo 1" />
        <img src={logo2} alt="Company Logo 2" />
        <img src={logo4} alt="Company Logo 4" />
        <img src={logo3} alt="Company Logo 3" />


      </div>
    </div>
  );
};

export default AffiliatesSection;
