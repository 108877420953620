import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import userIcon from "../../../Images/people.png";
import emailIcon from "../../../Images/email.png";
import phoneIcon from "../../../Images/smartphone.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const ClassicInsuranceForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    memberNumber: "",
    name: "",
    email: "",
    phone: "",
    vehicleType: "",
    brand: "",
    model: "",
    year: "",
    plate: "",
    comments: "",
    formType: "classicInsurance" 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        onClose();
      } else {
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="membership-form-overlay">
      <div className="membership-form-container">
        <div className="membership-form-header">
          <h2 className="membership-form-title">
            {t("TheClubRegistationForm.TheClubRegistationForm67")}
          </h2>
          <button
            className="close-membership-form"
            onClick={onClose}
            aria-label="Close form"
          >
            ✕
          </button>
        </div>
        <form className="membership-form" onSubmit={handleSubmit}>
          <p className="input-label">
            {t("TheClubRegistationForm.TheClubRegistationForm47")}
          </p>

          <div className="form-group with-icon">
            <input
              type="text"
              className="form-control"
              placeholder="Nº de Sócio CPAA"
              name="memberNumber"
              value={formData.memberNumber}
              onChange={handleChange}
              required
            />
            <img src={userIcon} alt="User Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              className="form-control"
              placeholder={t("TheClubRegistationForm.TheClubRegistationForm2")}
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <img src={userIcon} alt="User Icon" className="input-icon" />
          </div>
          <div className="form-group with-icon">
            <input
              type="email"
              className="form-control"
              placeholder={t("TheClubRegistationForm.TheClubRegistationForm4")}
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <img src={emailIcon} alt="Email Icon" className="input-icon" />
          </div>
          <div className="form-group phone-input-group">
            <PhoneInput
              international
              defaultCountry="PT"
              value={formData.phone}
              onChange={handlePhoneChange}
              className="my-phone-input"
              required
            />
            <img src={phoneIcon} alt="Phone Icon" className="input-icon" />
          </div>
          <div className="form-group">
            <select
              className="form-control"
              name="vehicleType"
              value={formData.vehicleType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                {t("TheClubRegistationForm.TheClubRegistationForm68")}
              </option>
              <option value="Automóvel clássico">
                Automóvel clássico
              </option>
              <option value="Motociclo clássico">
                Motociclo clássico
              </option>
            </select>
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              className="form-control"
              placeholder="Marca"
              name="brand"
              value={formData.brand}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              className="form-control"
              placeholder="Modelo"
              name="model"
              value={formData.model}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              className="form-control"
              placeholder="Ano"
              name="year"
              value={formData.year}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group with-icon">
            <input
              type="text"
              className="form-control"
              placeholder="Matrícula"
              name="plate"
              value={formData.plate}
              onChange={handleChange}
              required
            />
          </div>
          <p className="input-label">
            {t("TheClubRegistationForm.TheClubRegistationForm48")}
          </p>

          <div className="form-group">
            <textarea
              className="form-control"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className="submit-button">
              {t("TheClubRegistationForm.TheClubRegistationForm46")}
            </button>
            <p className="submit-text">
              {t("TheClubRegistationForm.TheClubRegistationForm49")}
            </p>
          </div>
        </form>
        <div className="help-text">
          <p>{t("TheClubRegistationForm.TheClubRegistationForm50")}</p>
          <p>
            {t("recaptchaText")}{" "}
            {t("TheClubRegistationForm.TheClubRegistationForm51")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClassicInsuranceForm;
