import React from "react";
import Footer from "./Geral/Footer";
import ImageComponent from "./Club/TheClubVantages/ImageComponent";
import MemberBenefits from "./Club/TheClubVantages/MemberBenefits";
import AdditionalInfo from "./Club/TheClubVantages/AdditionalInfo";
import ParentComponent from "./Geral/ParentComponent";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";

const App = () => {
  return (
    <div>
      <ParentComponent />
      <ImageComponent />
      <MemberBenefits />
      <SocialMediaButtons />
      <AdditionalInfo />
      <Footer />
    </div>
  );
};

export default App;
