import React, { useState } from "react";
import "./MembershipInfo.css";
import { useTranslation } from "react-i18next";
import MembershipForm from "./MembershipFormCouncil";

const MembershipInfo = () => {
  const { t, i18n } = useTranslation();
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  return (
    <div className="membership-infoCouncil">
      <h2 className="membership-titleCouncil">
        {t("MembershipInfoCouncil.MembershipInfoCouncil")}
      </h2>
      <h3 className="membership-subtitleCouncil">
        {t("MembershipInfoCouncil.MembershipInfoCouncil2")}
      </h3>
      <ul className="membership-listCouncil">
        <li>José Carlos Barquinha</li>
      </ul>
      <h3 className="membership-subtitleCouncil">
        {t("MembershipInfoCouncil.MembershipInfoCouncil3")}
      </h3>
      <ul className="membership-listCouncil">
        <li>Ricardo Seara Cardoso</li>
        <li>Patrícia Brandão</li>
        <li>João Redondo (Motos – Norte)</li>
        <li>António Smith (Motos – Sul)</li>
        <li>Victor Albuquerque</li>
        <li>Pedro Albuquerque</li>
        <li>Fernando Avelar</li>
        <li>Fernando Guilherme</li>
        <li>José Amador</li>
        <li>Fernando Martins</li>
        <li>Adriano Pontes (Açores – Terceira)</li>
        <li>Filipe Ponte (Açores – São Miguel)</li>
        <li>Luis Brito (Algarve)</li>
      </ul>
      <button
        className="join-button"
        onClick={() => setShowMembershipForm(true)}
      >
        {t("TheClubRegistationForm.TheClubRegistationForm63")}
      </button>
      {showMembershipForm && (
        <MembershipForm onClose={() => setShowMembershipForm(false)} />
      )}
    </div>
  );
};

export default MembershipInfo;
