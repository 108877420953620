import React from "react";
import "./TopImage.css";
import bannerImage from "../../../Images/1CPAA-Inverno-4k-4347-1.jpg";
import { useTranslation } from "react-i18next";

const TopImage = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-container">
      <img
        src={bannerImage}
        alt="Join the Classic Car Club"
        className="banner-image"
      />
      <div className="banner-overlay"></div> {}
      <div className="banner-text">
        <h1 className="banner-title">{t("TopImage.Title")}</h1>
        <p className="banner-subtitle">{t("TopImage.SubTitle")}</p>
      </div>
    </div>
  );
};

export default TopImage;
