// VideoBlock.js
import React from 'react';

const VideoBlock = ({ url }) => (
    <div className="video-block">
        <video controls src={url} className="video-content" />
    </div>
);

export default VideoBlock;
