import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../firebase-config";
import { getDocs, collection, query, where, Timestamp } from "firebase/firestore";
import ParagraphBlock from "./Articles/ParagraphBlock";
import ImageGalleryBlock from "./Articles/ImageGalleryBlock";
import VideoBlock from "./Articles/VideoBlock";
import AuthorImageBlock from "./Articles/AuthorImageBlock";
import RegistrationFormBlock from "./Articles/RegistrationFormBlock";
import RegistrationFormPorto from "./Articles/RegistrationFormPorto";
import RegistrationFormLisbon from "./Articles/RegistrationFormLisbon"; // Import Lisbon-specific form
import SubtitleBlock from "./Articles/SubtitleBlock";
import RandomPageImageBlock from "./Articles/RandomPageImageBlock";
import YouTubeVideoBlock from "./Articles/YouTubeVideoBlock";
import Footer from "./Geral/Footer";
import ParentComponent from "./Geral/ParentComponent";
import "./Articles/ArticleDetailPage.css";
import { useTranslation } from "react-i18next";
import TitleBlock from "./Articles/TitleBlock";
import ProgramBlock from "./Articles/ProgramBlock";

const ArticleDetailPage = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchArticle = async () => {
      if (!slug) {
        console.error("Slug is undefined");
        return;
      }

      const slugParts = decodeURIComponent(slug).split("-");
      const createdAt = slugParts.pop();
      const titlePt = slugParts.join("-").replace(/_/g, " ");

      const createdAtTimestamp = parseInt(createdAt, 10);
      if (isNaN(createdAtTimestamp)) {
        console.error("Invalid timestamp in slug");
        return;
      }

      try {
        const q = query(
          collection(firestore, "articles"),
          where("titlePt", "==", titlePt),
          where("createdAt", "==", Timestamp.fromMillis(createdAtTimestamp))
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setArticle(querySnapshot.docs[0].data());
        } else {
          console.log("No such article!");
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchArticle();
  }, [slug]);

  const renderContentBlock = (block) => {
    switch (block.type) {
      case "paragraph":
        return <ParagraphBlock content={block.content} />;
      case "imageGallery":
        return <ImageGalleryBlock images={block.images} />;
      case "video":
        return <VideoBlock url={block.content} />;
      case "authorImage":
        return <AuthorImageBlock imageUrl={block.content} />;
      case "registrationForm":
        return <RegistrationFormBlock {...block.details} />;
      case "portoRegistrationForm":
        return <RegistrationFormPorto {...block.details} />;
      case "lisbonRegistrationForm":  // New Lisbon-specific registration form
        return <RegistrationFormLisbon {...block.details} />;
      case "subtitle":
        return <SubtitleBlock content={block.content} />;
      case "randomPageImage":
        return <RandomPageImageBlock image={block.content} />;
      case "youtubeVideo":
        return <YouTubeVideoBlock url={block.content} />;
      case "title":
        return <TitleBlock titleEn={block.titleEn} titlePt={block.titlePt} />;
      case "program":
        if (block.details) {
          return <ProgramBlock dateEn={block.details.dateEn} datePt={block.details.datePt} activities={block.details.activities} />;
        }
        break;
      default:
        return null;
    }
  };

  return (
    <div>
      <ParentComponent />
      {article ? (
        <div className="wrapper">
          <img
            src={article.mainImage}
            alt={article.titleEn || article.titlePt || "Article Image"}
            className="main-article-image"
          />
          <TitleBlock titleEn={article.titleEn} titlePt={article.titlePt} />
          {article.contentBlocks &&
            article.contentBlocks.map((block, index) => (
              <div key={index} className="content-block">
                {renderContentBlock(block)}
              </div>
            ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <Footer />
    </div>
  );
};

export default ArticleDetailPage;
